import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Flex, Text } from '@mantine/core';
import { questionsListData } from '../data.js';
import { AppContext } from './AppContext';

export default function Header() {
  const navigate = useNavigate();
  const { globalState } = React.useContext(AppContext);

  function handleQuestionChange(value) {
    navigate('/q/' + value);
  }

  return (
    <Flex justify="center" align="center" style={{ height: '100%' }}>
      <Text c="blue" fw={700} style={{ marginRight: '20px' }}>
        Q. No. 
      </Text>
      <Select
        size="md"
        data={questionsListData}
        defaultValue={globalState.questionNum}
        value={globalState.questionNum}
        searchable
        nothingFoundMessage="Enter correct question number"
        checkIconPosition="right"
        onChange={handleQuestionChange}
      />
    </Flex>
  );
}