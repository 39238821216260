import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { MantineProvider, createTheme } from '@mantine/core';

import '@mantine/core/styles/global.css';
import '@mantine/core/styles/UnstyledButton.css';
import '@mantine/core/styles/Button.css';
import '@mantine/core/styles/Center.css';
import '@mantine/core/styles/Input.css';
import '@mantine/core/styles/PasswordInput.css';
import '@mantine/core/styles/Title.css';
import '@mantine/core/styles/Text.css';
import '@mantine/core/styles/ActionIcon.css';
import '@mantine/core/styles/Badge.css';
import '@mantine/core/styles/Flex.css';
import '@mantine/core/styles/Grid.css';
import '@mantine/core/styles/SimpleGrid.css';
import '@mantine/core/styles/Group.css';
import '@mantine/core/styles/Stack.css';
import '@mantine/core/styles/AppShell.css';
import '@mantine/core/styles/Container.css';
import '@mantine/core/styles/Dialog.css';
import '@mantine/core/styles/Modal.css';
import '@mantine/core/styles/Paper.css';
import '@mantine/core/styles/Card.css';
import '@mantine/core/styles/Loader.css';
import '@mantine/core/styles/Alert.css';
import '@mantine/core/styles/ModalBase.css';
import '@mantine/core/styles/Modal.css';
import '@mantine/core/styles/Overlay.css';
import '@mantine/core/styles/Divider.css';
import '@mantine/core/styles/Table.css';
import '@mantine/core/styles/Pill.css';
import '@mantine/core/styles/PillsInput.css';
import '@mantine/core/styles/FloatingIndicator.css';
import '@mantine/core/styles/SegmentedControl.css';

import '@mantine/core/styles/ScrollArea.css';
import '@mantine/core/styles/Popover.css';
import '@mantine/core/styles/CloseButton.css';
import '@mantine/core/styles/Combobox.css';
import '@mantine/core/styles/InlineInput.css';

import '@mantine/core/styles/ScrollArea.css';

import AppContainer from './components/AppContainer';
import QuestionsList from './pages/QuestionsList';
import Search from './pages/Search';
import { AppContext } from './components/AppContext';


function Index() {
  const [ globalState, setGlobalState ] = React.useState([]);

  const router = createBrowserRouter([{
    path: '/',
    element: <QuestionsList />,
  }, {
    path: '/q',
    element: <AppContainer />,
    children: [{
      path: ':num',
      element: <Search />
    }]
  }]);

  const theme = createTheme({
    fontFamily: `"Inter", sans-serif`
  });
  
  return (
    <AppContext.Provider value={{ globalState, setGlobalState }}>
      <MantineProvider theme={theme}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </MantineProvider>
    </AppContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index/>)

