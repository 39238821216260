import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Container, Flex, ActionIcon } from '@mantine/core';
import { IconChevronRight, IconChevronLeft, IconLayoutGridFilled } from '@tabler/icons-react';

import { AppContext } from './AppContext';
import { questionsListData } from '../data.js';

export default function Footer() {
	const { globalState } = React.useContext(AppContext);
	const [ nav, setNav ] = React.useState({ prev: null, next: null });
	const navigate = useNavigate();

	// Initialize search after every change in globalState.
  React.useEffect(() => {
  	if (! globalState || ! globalState.questionNum) { return; }

  	const index = questionsListData.indexOf(globalState.questionNum);

    setNav({
    	prev: index > 0 ? questionsListData[index - 1] : null,
    	next: index < questionsListData.length - 1 ? questionsListData[index + 1] : null
    });
  }, [ globalState ]);

	return (
			<Container>
				<Grid justify="space-between" align="center" 
					style={{ width: '100%', height: '100%' }}
					styles={{
						inner: { marginBottom: '0px', marginTop: '0px' }
					}}
				>
					<Grid.Col span={5}>
						{
							nav.prev ? 
							(
								<Button size="md" variant="outline" fullWidth 
									leftSection={<IconChevronLeft />} justify="space-between"
									onClick={() => { navigate(nav.prev); window.scrollTo(0, 0);}}
									style={{ width: '100%' }}> {/* For compatibility with older browsers */}
									{nav.prev}
								</Button>
							) : <></>
						}
					</Grid.Col>

					<Grid.Col span={2}>
						{/*<Button size="lg" variant="outline" fullWidth>All</Button>*/}
						<Flex justify="center">
							<ActionIcon variant="subtle" size="xl" onClick={() => navigate('/')}>
								<IconLayoutGridFilled />
							</ActionIcon>
						</Flex>
					</Grid.Col>

					<Grid.Col span={5}>
						{
							nav.next ?
							(
								<Button size="md" variant="outline" fullWidth 
									rightSection={<IconChevronRight />} justify="space-between"
									onClick={() => { navigate(nav.next); window.scrollTo(0, 0);}}
									style={{ width: '100%' }}> {/* For compatibility with older browsers */}
									{nav.next}
								</Button>
							) : <></>
						}
					</Grid.Col>
				</Grid>
			</Container>
	);
}