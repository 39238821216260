import { useNavigate } from 'react-router-dom';
import { Container, SimpleGrid, Flex, Text, Title, Space, Divider } from '@mantine/core';

import { questionsListData } from '../../data.js';

import styles from './index.module.css';

function QuestionsList() {
	const navigate = useNavigate();

	return (
		<Container>
			<Flex justify="center" align="center" direction="column"
				style={{ textAlign: 'center'}}>
				<Space h="md" />

				<Title order={1}>
					<Text span variant="gradient"
						gradient={{ from: 'blue', to: 'cyan', degree: 90 }} inherit>SEEEPC Quick Reference</Text>
				</Title>

				<Space h="md" />

				<Text size="lg" fw={500}>
					ప్రారంభించడానికి ప్రశ్న సంఖ్యను ఎంచుకోండి <br/>
					Select a question number to begin
				</Text>
			</Flex>

			<Space h="md" />

			<SimpleGrid 
				cols={{ base: 3, sm: 4, md: 5, lg: 6 }}
      	spacing={{ base: 10, sm: 'xl' }}
      	verticalSpacing={{ base: 'md', sm: 'xl' }}>
				{
					questionsListData.map((num) => (
						<Flex justify="center" align="center" className={styles.questionBox} 
							onClick={() => navigate('/q/' + num)} style={{ cursor: 'pointer' }}
							key={num}>
							<Text c="pink" fw={700} size="xl">{num}</Text>
						</Flex>
					))
				}
			</SimpleGrid>

			<Divider my="md" label="-#-" />
		</Container>
	);
}

export default QuestionsList;