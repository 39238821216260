import * as React from 'react';
import Fuse from 'fuse.js';
import { Container, TextInput, Table, Text, Space } from '@mantine/core';
import { useField } from '@mantine/form';
import data from '../../data.js';
import { AppContext } from '../../components/AppContext';


function Search() {
  const { globalState } = React.useContext(AppContext);

  // Contains the Fuse object.
  const [ fuse, setFuse ] = React.useState(null);

  // Contains the search results.
  const [ searchResults, setSearchResults ] = React.useState(null);

  const options = {
    includeScore: true,
    includeMatches: true,
    shouldSort: true,
    findAllMatches: false,
    threshold: 0.5,
    keys: [ 'code', 'value', 'valueEng' ],
  };

  const searchField = useField({
    initialValue: ''
  });

  /**
   * Highlight the matched part of the string.
   * 
   * @param  {Object} result The result object.
   * 
   * @return {String}        The highlighted string.
   */
  /* function highlightMatch(result) {
    const highlight = (inputText, regions) => {
      const c = 'search-highlight';
      let content = '';
      let nextUnhighlightedRegionStartingIndex = 0;

      regions.forEach(region => {
        const lastRegionNextIndex = region[1] + 1;

        content += [
          inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
          `<span class="${c}">`,
          inputText.substring(region[0], lastRegionNextIndex),
          '</span>',
        ].join('');

        nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
      });

      content += inputText.substring(nextUnhighlightedRegionStartingIndex);

      return content;
    };

    result.matches.forEach((match) => {
      result.item[match.key] = highlight(match.value, match.indices)
    });
    return result.item;
  } */

  /**
   * Called when the search string is changed.
   * 
   * @param  {Object} event The event object.
   * 
   * @return {void}
   */
  function handleSearch(event) {
    const searchString = event.target.value;
    searchField.setValue(searchString);

    // If the user searched for an empty string, display all data.
    if (searchString.length === 0) {
      setSearchResults(data[globalState.questionNum].entries);

      return;
    }

    const results = fuse.search(searchString);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  }

  // Initialize search after every change in globalState.
  React.useEffect(() => {
    /**
     * Initializes search.
     *
     * @param {String} questionNum The question number.
     * 
     * @return {void}
     */
    function initializeSearch(questionNum) {
      questionNum = questionNum || globalState.questionNum;
      setSearchResults(data[questionNum].entries);
      searchField.setValue('');

      setFuse(new Fuse(data[questionNum].entries, options));
    }

    initializeSearch(globalState.questionNum);
  }, [ globalState ]);


  return (
    <Container style={{ padding: '0px' }}>
      <Text c="blue" fw={500}>
        <Text span fw={700}>{globalState.questionNum}. </Text>
        { data[globalState.questionNum].question }
      </Text>
      <Space h="sm" />
      <TextInput
        {...searchField.getInputProps()}
        label=""
        placeholder="Search"
        size="lg"
        onChange={handleSearch}
      />

      <Table stickyHeader verticalSpacing="sm" horizontalSpacing="lg" striped
        highlightOnHover highlightOnHoverColor="blue.1"
        style={{ width: '100%', tableLayout: 'fixed' }}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ width: '50px' }}>Code</Table.Th>
            <Table.Th>Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          { searchResults && searchResults.map((entry, index) => (
            <Table.Tr key={index}>
              <Table.Td><Text size="lg">{entry.code}</Text></Table.Td>
              <Table.Td>
                <Text size="sm" dangerouslySetInnerHTML={{__html: entry.value}}></Text>
                <Text dangerouslySetInnerHTML={{__html: entry.valueEng}} >
                </Text>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        <Table.Tfoot style={{ 
            display: searchResults && searchResults.length === 0 ? 'table-footer-group' : 'none',
            backgroundColor: 'var(--mantine-color-gray-1)' 
          }}>
          <Table.Tr>
            <Table.Td colSpan={2} style={{ textAlign: 'center' }}>
              సరిపోలికలు ఏవీ కనుగొనబడలేదు. 
              'ఇతరులు' ఎంపిక అందుబాటులో ఉందో లేదో తనిఖీ చేయండి.<br />
              No matches found. Check if 'Others' option is available.
            </Table.Td>
          </Table.Tr>
        </Table.Tfoot>
      </Table>
    </Container>
  );
}

export default Search;
