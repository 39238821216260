const questionsListData = [ '3', '4', '5', '6', '7', '7-SC', '7-ST', '7-BC', '7-OC', '10', '13',
	'14', '15', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '32', '39',
	'41', '42', '43(a,b,c)', '44', '45(a)', '45(b)', '45(c)', '46', '47(a)', '47(b)', '47(c)', '47(d)',
	'48(b)', '48(c)', '49', '51', '52', '54(a)', '54(c)', '54(d)', '54(f)', '54(g)', '54(j)' ];
const data = {
	'3': {
		'number': '3',
		'question': 'కుటుంబ యజమానితో సంబంధాన్ని పేర్కొనండి (కుటుంబ యజమాని తానే అయితే “స్వయం” యొక్క కోడ్ వ్రాయండి)',
		'questionEng': `State the relationship with the head of the family (if the head of the family is himself, 
			enter a code of "Self")`,
		'entries': [{
			code: '01',
			value: 'స్వయం',
			valueEng: 'Self'
		}, {
			code: '02',
			value: 'భర్త',
			valueEng: 'Husband'
		}, {
			code: '03',
			value: 'భార్య',
			valueEng: 'Wife'
		}, {
			code: '04',
			value: 'కొడుకు',
			valueEng: 'Son'
		}, {
			code: '05',
			value: 'కూతురు',
			valueEng: 'Daughter'
		}, {
			code: '06',
			value: 'కోడలు',
			valueEng: 'Daughter-in-law'
		}, {
			code: '07',
			value: 'అల్లుడు',
			valueEng: 'Son-in-law'
		}, {
			code: '08',
			value: 'తండ్రి',
			valueEng: 'Father'
		}, {
			code: '09',
			value: 'తల్లి',
			valueEng: 'Mother'
		}, {
			code: '10',
			value: 'అత్త',
			valueEng: 'Aunt'
		}, {
			code: '11',
			value: 'మామ',
			valueEng: 'Uncle'
		}, {
			code: '12',
			value: 'అన్న / తమ్ముడు',
			valueEng: 'Elder / Younger brother'
		}, {
			code: '13',
			value: 'అక్క / చెల్లి',
			valueEng: 'Sister'
		}, {
			code: '14',
			value: 'తాత',
			valueEng: 'Grandfather'
		}, {
			code: '15',
			value: 'అమ్మమ్మ / నాన్నమ్మ',
			valueEng: 'Grandmother / Paternal Grandmother'
		}, {
			code: '16',
			value: 'మనవడు',
			valueEng: 'Grandson'
		}, {
			code: '17',
			value: 'మనమరాలు',
			valueEng: 'Granddaughter'
		}, {
			code: '18',
			value: 'బావ / బావమరిది',
			valueEng: 'Brother-in-law'
		}, {
			code: '19',
			value: 'వదిన / మరదలు',
			valueEng: 'Vadina / Maradalu'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'4': {
		number: '4',
		question: 'లింగ వివరాలను తెలియజేయండి.',
		questionEng: 'Give gender details.',
		'entries': [{
			code: '01',
			value: 'పురుషుడు',
			valueEng: 'Male'
		}, {
			code: '02',
			value: 'స్త్రీ',
			valueEng: 'Female'
		}, {
			code: '03',
			value: 'ఇతురులు (Third Gender)',
			valueEng: 'Others (Third Gender)'
		}]
	},
	'5': {
		number: '5',
		question: 'ఇంటిలోని ప్రతి సభ్యునికి సంబంధించిన మత వివరాలను పేర్కొనండి.',
		questionEng: 'Mention the religious details of each member of the household.',
		entries: [{
			code: '01',
			value: 'ఏ మతం లేదు',
			valueEng: 'No religion'
		}, {
			code: '02',
			value: 'హిందు',
			valueEng: 'Hindu'
		}, {
			code: '03',
			value: 'ఇస్లాం',
			valueEng: 'Islam'
		}, {
			code: '04',
			value: 'క్రిస్టియన్',
			valueEng: 'Christian'
		}, {
			code: '05',
			value: 'సిక్కు',
			valueEng: 'Sikh'
		}, {
			code: '06',
			value: 'బౌద్ధ',
			valueEng: 'Buddhism'
		}, {
			code: '07',
			value: 'జైన',
			valueEng: 'Jain'
		}, {
			code: '00',
			value: 'ఇతురులు',
			valueEng: 'Others'
		}]
	},
	'6': {
		number: '6',
		question: 'ఇంటి సభ్యులందరి సామాజిక వర్గాన్ని పేర్కొనండి.',
		questionEng: 'Mention the social category of all household members.',
		entries: [{
			code: '01',
			value: 'ఏ సామాజిక వర్గం లేదు',
			valueEng: 'No social category'
		}, {
			code: '02',
			value: 'SC',
			valueEng: 'SC'
		}, {
			code: '03',
			value: 'ST',
			valueEng: 'ST'
		}, {
			code: '04',
			value: 'BC',
			valueEng: 'BC'
		}, {
			code: '05',
			value: 'OC',
			valueEng: 'OC'
		}]
	},
	'7': {
		number: '7',
		question: 'కులం వివరాలు పేర్కొనండి',
		questionEng: 'Mention caste details',
		entries: [{
			code: '999',
			value: 'ఏ కులము లేదు',
			valueEng: 'No caste'
		}, {
			code: '',
			value: 'కులం వివరాలు ఉన్నట్లైతే అనుబంధం - 7-SC, 7-ST, 7-BC, 7-OC ప్రకారం కోడ్ వ్రాయండి.',
			valueEng: 'Write the code as per 7-SC, 7-ST, 7-BC, 7-OC if caste details are available.'
		}]
	},
	'7-SC': {
		number: '7-SC',
		question: 'షెడ్యూల్డ్ కులాల జాబితా (SC)',
		questionNum: 'Scheduled Castes List',
		entries: [{
			code: '001',
			value: 'ఆది ఆంధ్ర',
			valueEng: 'Adi Andhra'
		}, {
			code: '002',
			value: 'ఆది ద్రావిడ',
			valueEng: 'Adi Dravida'
		}, {
			code: '003',
			value: 'అనాముక్',
			valueEng: 'Anamuk'
		}, {
			code: '004',
			value: 'అరైమాల',
			valueEng: 'Aray Mala'
		}, {
			code: '005',
			value: 'అరుంధతీయ',
			valueEng: 'Arundhatiya'
		}, {
			code: '006',
			value: 'అర్వ మాల',
			valueEng: 'Arwa Mala'
		}, {
			code: '007',
			value: 'బారికి',
			valueEng: 'Bariki'
		}, {
			code: '008',
			value: 'బావురి',
			valueEng: 'Bavuri'
		}, {
			code: '009',
			value: 'బేడ(బుడగ) జంగం',
			valueEng: 'Beda (Budga) Jangam'
		}, {
			code: '010',
			value: 'బైండ్ల',
			valueEng: 'Bindla'
		}, {
			code: '011',
			value: 'బ్యాగర, బ్యాగరి',
			valueEng: 'Byagara, Byagari'
		}, {
			code: '012',
			value: 'చాచటి',
			valueEng: 'Chachati'
		}, {
			code: '013',
			value: 'చాలవడి',
			valueEng: 'Chalavadi'
		}, {
			code: '014',
			value: 'చమార్, మోచి, ముచి, చమార్-రవిదాస్, చమార్-రోహిదాస్',
			valueEng: 'Chamar, Mochi, Muchi, Chamar-Ravidas, Chamar-Rohidas'
		}, {
			code: '015',
			value: 'చంభర్',
			valueEng: 'Chambhar'
		}, {
			code: '016',
			value: 'చండాల',
			valueEng: 'Chandala'
		}, {
			code: '017',
			value: 'డక్కల్, డొక్కల్వార్',
			valueEng: 'Dakkal, Dokkalwar'
		}, {
			code: '018',
			value: 'దండాసి',
			valueEng: 'Dandasi'
		}, {
			code: '019',
			value: 'ధోర్',
			valueEng: 'Dhor'
		}, {
			code: '020',
			value: 'డోమ్, దొంబర, పైడి, పనో',
			valueEng: 'Dom, Dombara, Paidi, Pano'
		}, {
			code: '021',
			value: 'ఎల్లమలవార్, ఎల్లమ్మలవాండ్లు',
			valueEng: 'Ellamalawar, Yellammalawandlu'
		}, {
			code: '022',
			value: 'ఘాసి, హడ్డి, రెల్లి, చంచండి',
			valueEng: 'Ghasi, Haddi, Relli, Chanchandi'
		}, {
			code: '023',
			value: 'గోదారి',
			valueEng: 'Godari'
		}, {
			code: '024',
			value: 'గోసంగి',
			valueEng: 'Gosangi'
		}, {
			code: '025',
			value: 'హోలెయా',
			valueEng: 'Holeya'
		}, {
			code: '026',
			value: 'హోలెయ దాసరి',
			valueEng: 'Holeya Dasari'
		}, {
			code: '027',
			value: 'జగ్గలి',
			valueEng: 'Jaggali'
		}, {
			code: '028',
			value: 'జంబువులు',
			valueEng: 'Jambuvulu'
		}, {
			code: '029',
			value: 'కొలుపుల్వాండ్లు, పంబాడ, పంబండ, పంబాల',
			valueEng: 'Kolupulvandlu, Pambada, Pambanda, Pambala'
		}, {
			code: '030',
			value: 'మాదాసి కురువ, మదారి కురువ',
			valueEng: 'Madasi Kuruva, Madari Kuruva'
		}, {
			code: '031',
			value: 'మాదిగ',
			valueEng: 'Madiga'
		}, {
			code: '032',
			value: 'మాదిగ దాసు, మస్తీన్',
			valueEng: 'Madiga Dasu, Mashteen'
		}, {
			code: '033',
			value: 'మహర్',
			valueEng: 'Mahar'
		}, {
			code: '034',
			value: 'మాల, మాల అయ్యవారు',
			valueEng: 'Mala, Mala Ayawaru'
		}, {
			code: '035',
			value: 'మాల దాసరి',
			valueEng: 'Mala Dasari'
		}, {
			code: '036',
			value: 'మాల దాసు',
			valueEng: 'Mala Dasu'
		}, {
			code: '037',
			value: 'మాలా హన్నాయి',
			valueEng: 'Mala Hannai'
		}, {
			code: '038',
			value: 'మాల జంగం',
			valueEng: 'Malajangam'
		}, {
			code: '039',
			value: 'మాల మస్తీ',
			valueEng: 'Mala Masti'
		}, {
			code: '040',
			value: 'మాల సాలె, నేతకాని',
			valueEng: 'Mala Sale, Netkani'
		}, {
			code: '041',
			value: 'మాల సన్యాసి',
			valueEng: 'Mala Sanyasi'
		}, {
			code: '042',
			value: 'మాంగ్',
			valueEng: 'Mang'
		}, {
			code: '043',
			value: 'మాంగ్ గరోడి',
			valueEng: 'Mang Garodi'
		}, {
			code: '044',
			value: 'మన్నే',
			valueEng: 'Manne'
		}, {
			code: '045',
			value: 'మాష్టి',
			valueEng: 'Mashti'
		}, {
			code: '046',
			value: 'మాతంగి',
			valueEng: 'Matangi'
		}, {
			code: '047',
			value: 'మెహతార్',
			valueEng: 'Mehtar'
		}, {
			code: '048',
			value: 'మిత అయ్యాళ్వార్',
			valueEng: 'Mitha Ayyalvar'
		}, {
			code: '049',
			value: 'ముందల',
			valueEng: 'Mundala'
		}, {
			code: '050',
			value: 'పాకీ, మోతీ, తోటి',
			valueEng: 'Paky, Moti, Thoti'
		}, {
			code: '051',
			value: 'పామిడి',
			valueEng: 'Pamidi'
		}, {
			code: '052',
			value: 'పంచమ, పరియా',
			valueEng: 'Panchama, Pariah'
		}, {
			code: '053',
			value: 'రెల్లి',
			valueEng: 'Relli'
		}, {
			code: '054',
			value: 'సమగర',
			valueEng: 'Samagara'
		}, {
			code: '055',
			value: 'సంబన్',
			valueEng: 'Samban'
		}, {
			code: '056',
			value: 'సప్రూ',
			valueEng: 'Sapru'
		}, {
			code: '057',
			value: 'సింధోల్లు, చిందోల్లు',
			valueEng: 'Sindhollu, Chindollu'
		}, {
			code: '058',
			value: 'యాతల',
			valueEng: 'Yatala'
		}, {
			code: '059',
			value: 'వల్లూవాన్',
			valueEng: 'Valluvan'
		}]
	},
	'7-ST': {
		number: '7-ST',
		question: 'షెడ్యూల్డ్ తెగల జాబితా (ST)',
		questionEng: 'Scheduled Tribes List (ST)',
		entries: [{
			code: '001',
			value: 'అంద్, సాధు అంధ్',
			valueEng: 'Andh, Sadhu Andh'
		}, {
			code: '002',
			value: 'బగటా',
			valueEng: 'Bagata'
		}, {
			code: '003',
			value: 'బిల్',
			valueEng: 'Bhil'
		}, {
			code: '004',
			value: 'చెంచు',
			valueEng: 'Chenchu'
		}, {
			code: '005',
			value: 'గడబాస్, బోడో గడబ, గుతోబ్, గడబ, కల్లాయి గడబ, పరంగి, గడబ, కతేర గడబ, కాపు గడబ',
			valueEng: 'Gadabas, Bodo Gadaba, Gutob Gadaba, Kallayi Gadaba, Parangi Gadaba, Kathera Gadaba, Kapu Gadaba'
		}, {
			code: '006',
			value: 'గోండ్, నాయక్ పోడ్, రాజ్తోండ్, కోయిటూర్',
			valueEng: 'Gond, Naikpod, Rajgond, Koitur'
		}, {
			code: '007',
			value: 'గౌడు (ఏజెన్సీ ప్రాంతాలలో)',
			valueEng: 'Goudu (in the Agency tracts) '
		}, {
			code: '008',
			value: 'హిల్ రెడ్లు',
			valueEng: 'Hill Reddis'
		}, {
			code: '009',
			value: 'జటాపులు',
			valueEng: 'Jatapus'
		}, {
			code: '010',
			value: 'కమ్మర',
			valueEng: 'Kammara'
		}, {
			code: '011',
			value: 'కట్టునాయకన్',
			valueEng: 'Kattunayakan'
		}, {
			code: '012',
			value: 'కొలం, కొలవార్',
			valueEng: 'Kolam, Kolawar'
		}, {
			code: '013',
			value: 'కొండ దొరలు, కుబి',
			valueEng: 'Konda Dhoras, Kubi '
		}, {
			code: '014',
			value: 'కొండ కాపులు',
			valueEng: 'Konda Kapus'
		}, {
			code: '015',
			value: 'కొండ రెడ్లు',
			valueEng: 'Kondareddis'
		}, {
			code: '016',
			value: 'కొండులు, కోడి, కోధు, దేశాయ కోండ్స్, డోంగ్రియా కోండ్స్, కుట్టియా కోండ్స్, టికిరియా కోండ్స్, యెనిటీ కోండ్స్, కువింగ',
			valueEng: 'Kondhs, Kodi, Kodhu, Desaya Kondhs, Dongria Kondhs, Kuttiya Kondhs, Tikiria Kondhs, Yenity Kondhs, Kuvinga'
		}, {
			code: '017',
			value: 'కోటియా, బెంతో ఒరియా, బర్తీక, దులియా, హోల్య, సన్రోనా, సిధోపైకో',
			valueEng: 'Kotia, Bentho Oriya, Bartika, Dulia, Holya, Sanrona, Sidhopaiko'
		}, {
			code: '018',
			value: 'కోయ, డోలి కోయ, గుట్ట కోయ, కమ్మర కోయ, ముసర కోయ, ఒడ్డి కోయ, పట్టిడి కోయ, రాజా, రాషా కోయ, లింగధారి కోయ (సాధారణ), కొట్టు కోయ, భీనే కోయ, రాజకీయ',
			valueEng: 'Koya, Doli Koya, Gutta Koya, Kammara Koya, Musara Koya, Oddi Koya, Pattidi Koya, Rajah, Rasha Koya, Lingadhari Koya (ordinary), Kottu Koya, Bhine Koya, Rajkoya'
		}, {
			code: '019',
			value: 'కులియ',
			valueEng: 'Kulia'
		}, {
			code: '020',
			value: 'మన్న దొర',
			valueEng: 'Manna Dhora'
		}, {
			code: '021',
			value: 'ముఖ దొర, నూక దొర',
			valueEng: 'Mukha Dhora, Nooka Dhora'
		}, {
			code: '022',
			value: 'నాయకులు (ఏజెన్సీ ప్రాంతాలలో )',
			valueEng: 'Nayaks (in the Agency tracts)'
		}, {
			code: '023',
			value: 'పర్దాన్',
			valueEng: 'Pardhan'
		}, {
			code: '024',
			value: 'పోర్జా, పరంగి పెర్జా',
			valueEng: 'Porja, Parangiperja'
		}, {
			code: '025',
			value: 'రెడ్డి దొరలు',
			valueEng: 'Reddi Dhoras'
		}, {
			code: '026',
			value: 'రోనా, రెనా',
			valueEng: 'Rona, Rena'
		}, {
			code: '027',
			value: 'సవర్లు, కాపు సవర్లు, మాలియా సవర్లు, ఖుట్టో సవర్లు',
			valueEng: 'Savaras, Kapu Savaras, Maliya Savaras, Khutto Savaras'
		}, {
			code: '028',
			value: 'సుగాలీలు, లంబాడీలు, బంజారాలు',
			valueEng: 'Sugalis, Lambadis, Banjara'
		}, {
			code: '029',
			value: 'తోటి',
			valueEng: 'Thoti'
		}, {
			code: '030',
			value: 'యేనాదీలు, చెల్ల యేనాది, కప్పల యేనాది, మంచి యేనాది, రెడ్డి యేనాది',
			valueEng: 'Yenadis, Chella Yenadi, Kappala Yenadi, Manchi Yenadi, Reddi Yenadi'
		}, {
			code: '031',
			value: 'యెరుకల, కొరచ, డబ్బా యెరుకల, కుంచపురి యెరుకల, ఉప్పు యెరుకల',
			valueEng: 'Yerukulas, Koracha, Dabba Yerukula, Kunchapuri Yerukula, Uppu Yerukula'
		}, {
			code: '032',
			value: 'నక్కల, కుర్వికరణ్',
			valueEng: 'Nakkala, Kurvikaran'
		}]
	},
	'7-BC': {
		number: '7-BC',
		question: 'వెనుక బడిన తరగతులు (BC)',
		questionEng: 'Backward Classes (BC)',
		entries: [{
			code: '001',
			value: 'BC-A: అగ్నికులక్షత్రియ, పల్లి, వడబలిజ, బెస్త, జాలరి, గంగవర్, గంగపుత్ర, గూండ్ల, వన్యకులక్షత్రియ (వన్నెకాపు, వన్నెరెడ్డి, పల్లికాపు, పల్లిరెడ్డి) నెయ్యాల, పట్టపు',
			valueEng: 'BC-A: Agnikulakshatriya, Palli, Vadabalija, Bestha, Jalari, Gangavar, Gangaputra, Goondla, Vanyakulakshatriya (Vannekapu, Vannereddi, Pallikapu, Pallireddi) Neyyala, Pattapu'
		}, {
			code: '002',
			value: 'BC-A: బాలసంతు, బహురూపి',
			valueEng: 'BC-A: Balasanthu, Bahurupi'
		}, {
			code: '003',
			value: 'BC-A: బుడబుక్కల',
			valueEng: 'BC-A: Budabukkala'
		}, {
			code: '004',
			value: 'BC-A: రజక (చాకలి, వన్నార్)',
			valueEng: 'BC-A: Rajaka (Chakali, Vannar)'
		}, {
			code: '005',
			value: 'BC-A: దాసరి (గతంలో బిక్షాటన చేసిన బిచ్చగాడు)',
			valueEng: 'BC-A: Dasari (formerly engaged in Bikshatana i.e., Beggary)'
		}, {
			code: '006',
			value: 'BC-A: దొమ్మర',
			valueEng: 'BC-A: Dommara'
		}, {
			code: '007',
			value: 'BC-A: గంగిరెడ్లవారు',
			valueEng: 'BC-A: Gangiredlavaru'
		}, {
			code: '008',
			value: 'BC-A: జంగం (ఇతని సాంప్రదాయ వృత్తి భిక్షాటన)',
			valueEng: 'BC-A: Jangam (whose traditional occupation is begging)'
		}, {
			code: '009',
			value: 'BC-A: జోగి',
			valueEng: 'BC-A: Jogi'
		}, {
			code: '010',
			value: 'BC-A: కాటిపాపల',
			valueEng: 'BC-A: Katipapala'
		}, {
			code: '011',
			value: 'BC-A: మేదరి లేదా మహేంద్ర',
			valueEng: 'BC-A: Medari or Mahendra'
		}, {
			code: '012',
			value: 'BC-A: మొండివారు. మొండిబండ, బండ',
			valueEng: 'BC-A: మొండివారు. మొండిబండ, బండ'
		}, {
			code: '013',
			value: 'BC-A: నాయి-బ్రాహ్మణ / నాయి (మంగళి), మంగళ మరియు భజంత్రి',
			valueEng: 'BC-A: Nayi-Brahmin / Nayee-Brahmin (Mangali), Mangala and Bhajantri'
		}, {
			code: '014',
			value: 'BC-A: వంశ రాజ్ / పిచ్చిగుంట్ల',
			valueEng: 'BC-A: Vamsha Raj / Pitchiguntla'
		}, {
			code: '015',
			value: 'BC-A: పాముల',
			valueEng: 'BC-A: Pamula'
		}, {
			code: '016',
			value: 'BC-A: పార్టీ (నిర్షికారి)',
			valueEng: 'BC-A: Pardhi (Nirshikari)'
		}, {
			code: '017',
			value: 'BC-A: పంబాల',
			valueEng: 'BC-A: Pambala'
		}, {
			code: '018',
			value: 'BC-A: పెద్దమ్మవాండ్లు, దేవరవాండ్లు, ఎల్లమ్మవాండ్లు, ముత్యాలమ్మవాండ్లు, దమ్మలి / దమ్మల / దమ్ముల / దా మల',
			valueEng: 'BC-A: Peddammavandlu, Devaravandlu, Yellammavandlu, Mutyalammavandlu, Dammali / Dammala / Dammula / Damala'
		}, {
			code: '019',
			value: 'BC-A: వీరముష్టి (నెట్టికోటల), వీరభద్రీయ',
			valueEng: 'BC-A: Veeramushti (Nettikotala), Veerabhadreeya'
		}, {
			code: '020',
			value: 'BC-A: వాల్మీకి బోయ (బోయ, బేదర, కిరాతక, నిషాది, యెల్లపి / యెల్లపు, పెద్ద బోయ), తలయారి, చుండువల్లు',
			valueEng: 'BC-A: Valmiki Boya (Boya, Bedar, Kirataka, Nishadi, Yellapi / Yellapu, Pedda Boya), Talayari, Chunduvallu'
		}, {
			code: '021',
			value: 'BC-A: గుడాల',
			valueEng: 'BC-A: Gudala'
		}, {
			code: '022',
			value: 'BC-A: కంజర భట్ట',
			valueEng: 'BC-A: Kanjara Bhatta'
		}, {
			code: '023',
			value: 'BC-A: కెప్మారే లేదా రెడ్డిక',
			valueEng: 'BC-A: Kepmare or Reddika'
		}, {
			code: '024',
			value: 'BC-A: మొండేపట్ట',
			valueEng: 'Mondepatta'
		}, {
			code: '025',
			value: 'BC-A: నొక్కర్',
			valueEng: 'BC-A: Nokkar'
		}, {
			code: '026',
			value: 'BC-A: పరికి ముగ్గుల',
			valueEng: 'BC-A: Pariki Muggula'
		}, {
			code: '027',
			value: 'BC-A: యాట',
			valueEng: 'BC-A: Yata'
		}, {
			code: '028',
			value: 'BC-A: చోపేమరి',
			valueEng: 'BC-A: Chopemari'
		}, {
			code: '029',
			value: 'BC-A: కైకడి',
			valueEng: 'BC-A: Kaikadi'
		}, {
			code: '030',
			value: 'BC-A: జోషినందివలు',
			valueEng: 'BC-A: Joshinandiwalas'
		}, {
			code: '031',
			value: 'BC-A: ఒడ్డె (ఒడ్డీలు, వడ్డి, వడ్డెలు), వడ్డెర, వడ్డభోవి, వడియరాజ్, వడ్డెర',
			valueEng: 'BC-A: Odde (Oddilu, Vaddi, Vaddelu), Vaddera, Vaddabhovi, Vadiyaraj, Waddera'
		}, {
			code: '032',
			value: 'BC-A: మందుల',
			valueEng: 'BC-A: Mandula'
		}, {
			code: '033',
			value: 'BC-A: మెహతార్ (ముస్లిం)',
			valueEng: 'BC-A: Mehtar (Muslim)'
		}, {
			code: '034',
			value: 'BC-A: కూనపులి',
			valueEng: 'BC-A: Kunapuli'
		}, {
			code: '035',
			value: 'BC-A: పత్ర',
			valueEng: 'BC-A: Patra'
		}, {
			code: '036',
			value: 'BC-A: పాల-ఏకరి, ఏకిల, వ్యాకుల, ఏకిరి, నాయనివారు, పాలెగారు, తొలగరి, కావలి',
			valueEng: 'BC-A: Pala-Ekari, Ekila, Vyakula, Ekiri, Nayanivaru, Palegaru, Tolagari, Kavali'
		}, {
			code: '037',
			value: 'BC-A: రాజన్నల, రాజన్నలు',
			valueEng: 'BC-A: Rajannala, Rajannalu'
		}, {
			code: '038',
			value: 'BC-A: బుక్క అయ్యవారు',
			valueEng: 'BC-A: Bukka Ayyavars'
		}, {
			code: '039',
			value: 'BC-A: గోత్రాల',
			valueEng: 'BC-A: Gotrala'
		}, {
			code: '040',
			value: 'BC-A: కాశికపడి / కాశికపూడి',
			valueEng: 'BC-A: Kasikapadi / Kasikapudi'
		}, {
			code: '041',
			value: 'BC-A: సిద్దుల',
			valueEng: 'BC-A: Siddula'
		}, {
			code: '042',
			value: 'BC-A: సిక్లిగర్ / సిక్లాగర్',
			valueEng: 'BC-A: Sikligar / Saikalgar'
		}, {
			code: '043',
			value: 'BC-A: పూసల',
			valueEng: 'BC-A: Poosala'
		}, {
			code: '044',
			value: `BC-A: అనాథ మరియు నిరాశ్రయులైన పిల్లలు పదేళ్ల వయస్సు రాకముందే తల్లిదండ్రులను కోల్పోయి 
			నిరాశ్రయులైన పిల్లలు మరియు చట్టం ప్రకారం వారిని చూసుకోవడానికి మరెవరూ లేరు మరియు ప్రభుత్వం నిర్వహించే 
			ఏదైనా పాఠశాలలు లేదా అనాథాశ్రమాలలో ప్రవేశం పొందిన వారు`,
			valueEng: `BC-A: Orphans and destitute children who have lost their parents before reaching 
			the age of ten and are destitute; and who have nobody else to take care of them either by 
			law or custom; and also who are admitted into any of the schools or orphanages run by the 
			Government or recognised by the Government.`
		}, {
			code: '045',
			value: 'BC-A: అడ్డపువారు',
			valueEng: 'BC-A: Addapuvaru'
		}, {
			code: '046',
			value: 'BC-A: బాగోతుల / భాగవతుల',
			valueEng: 'BC-A: Bagothula/ Bhagavathula'
		}, {
			code: '047',
			value: 'BC-A: బెయిల్ కమ్మర / ఘైసాడి / గడియా లోహర్',
			valueEng: 'BC-A: Bail Kammara / Ghisadi / Gadiya / Lohar'
		}, {
			code: '048',
			value: 'BC-A: ఏనూటి / యేనేటివాళ్ళు',
			valueEng: 'BC-A: Enooti / Yenetivallu'
		}, {
			code: '049',
			value: 'BC-A: గంజికూటి / గంజికూటివారు',
			valueEng: 'BC-A: Ganjikuti / Ganjikutivaru'
		}, {
			code: '050',
			value: 'BC-A: గౌడ జెట్టి',
			valueEng: 'BC-A: Gouda Jetti'
		}, {
			code: '051',
			value: 'BC-A: కాకి పడగల',
			valueEng: 'BC-A: Kakipadagala'
		}, {
			code: '052',
			value: 'BC-A: పటంవారు / మాసాయిలు',
			valueEng: 'BC-A: Patamvaru / Masaiahlu'
		}, {
			code: '053',
			value: 'BC-A: ఓయడ్ / ఓడ్ / ఆడ్',
			valueEng: 'BC-A: Odd / Od / Oad'
		}, {
			code: '054',
			value: 'BC-A: సొన్నాయిల / సన్నాయిల / సన్నాయోళ్లు',
			valueEng: 'BC-A: Sonnayila / Sannayila / Sannayollu'
		}, {
			code: '055',
			value: 'BC-A: శ్రీ క్షత్రియ రామజోగి / రామజోగి / రామజోగుల',
			valueEng: 'BC-A: Sri Kshatriya Ramajogi / Ramajogi / Ramajogula'
		}, {
			code: '056',
			value: 'BC-A: తెరచీరల / తెల్సూరి / బైకని',
			valueEng: 'BC-A: Theracheerala / Telsoori/ Baikani'
		}, {
			code: '057',
			value: 'BC-A: తొలుబొమ్మలాటవారు / బొప్పల',
			valueEng: 'BC-A: Tholubommalatavaru / Boppala'
		}, {
			code: '058',
			value: 'BC-B: ఆర్య క్షత్రియ, చిత్తారి, గినియార్, చిత్రకార, నఖాస్',
			valueEng: 'BC-B: Aryakshatriya, Chittari, Giniyar, Chitrakara, Nakhas'
		}, {
			code: '059',
			value: 'BC-B: దేవాంగ',
			valueEng: 'BC-B: Devanga'
		}, {
			code: '060',
			value: 'BC-B: గౌడ్ [ఈడిగ, గౌడ (గమల్ల), కలాలీ, గౌండ మరియు శ్రీసయన (సెగిడి)',
			valueEng: 'BC-B: Goud [Ediga, Gouda (Gamalla), Kalalee, Gounda, and Srisayana (Segidi)'
		}, {
			code: '061',
			value: 'BC-B: దూదేకుల, లద్దాఫ్, పింజరి లేదా నూర్బాష్',
			valueEng: 'BC-B: Dudekula, Laddaf, Pinjari or Noorbash'
		}, {
			code: '062',
			value: 'BC-B: గాండ్ల, తెలికుల, దేవతిలకుల',
			valueEng: 'BC-B: Gandla, Telikula, Devathilakula'
		}, {
			code: '063',
			value: 'BC-B: జాండ్రా',
			valueEng: 'BC-B: Jandra'
		}, {
			code: '064',
			value: 'BC-B: కుమ్మర లేదా కులాల, శాలివాహన',
			valueEng: 'BC-B: Kummara or Kulala, Salivahana'
		}, {
			code: '065',
			value: 'BC-B: కరికాలభక్తులు, కైకోలన్ లేదా కైకాల (సెంగుండం లేదా సెంగుంతేర్)',
			valueEng: 'BC-B: Karikalabhakthulu, Kaikolan or Kaikala (Sengundam or Sengunther)'
		}, {
			code: '066',
			value: 'BC-B: కర్ణభక్తులు',
			valueEng: 'BC-B: Karnabhakthulu'
		}, {
			code: '067',
			value: 'BC-B: కురుబ లేదా కురుమ',
			valueEng: 'BC-B: Kuruba or Kuruma'
		}, {
			code: '068',
			value: 'BC-B: నీలకంఠి',
			valueEng: 'BC-B: Neelakanthi'
		}, {
			code: '069',
			value: 'BC-B: పట్కర్ (ఖత్రి)',
			valueEng: 'BC-B: Patkar (Khatri)'
		}, {
			code: '070',
			value: 'BC-B: పెరిక (పెరిక బలిజ, పురగిరి క్షత్రియ)',
			valueEng: 'BC-B: Perika (Perika Balija, Puragiri kshatriya)'
		}, {
			code: '071',
			value: 'BC-B: నెస్సీ లేదా కుర్ని',
			valueEng: 'BC-B: Nessi or Kurni'
		}, {
			code: '072',
			value: 'BC-B: పద్మశాలి (సాలి, సాలివన్, పట్టుసాలి, సేనపతులు, తొగట శాలి)',
			valueEng: 'BC-B: Padmasali (Sali, Salivan, Pattusali, Senapathulu, Thogata Sali)'
		}, {
			code: '073',
			value: 'BC-B: స్వకులశాలి',
			valueEng: 'BC-B: '
		}, {
			code: '074',
			value: 'BC-B: తొగట, తొగటి లేదా తొగటవీరక్షత్రియ',
			valueEng: 'BC-B: Thogata, Thogati or Thogataveerakshatriya'
		}, {
			code: '075',
			value: 'BC-B: కంసాలి / ఔసుల',
			valueEng: 'BC-B: Goldsmith'
		}, {
			code: '076',
			value: 'BC-B: కమ్మరి',
			valueEng: 'BC-B: Blacksmith'
		}, {
			code: '077',
			value: 'BC-B: కంచరి',
			valueEng: 'BC-B: Brassmith'
		}, {
			code: '078',
			value: 'BC-B: వడ్రంగి / వడ్ల',
			valueEng: 'BC-B: Vadrangi / Vadla'
		}, {
			code: '079',
			value: 'BC-B: కాసి / శిల్పులు',
			valueEng: 'BC-B: Kasi / Silpis'
		}, {
			code: '080',
			value: 'BC-B: లోద్ / లోది / లోదా',
			valueEng: 'BC-B: Lodh / Lodhi / Lodha'
		}, {
			code: '081',
			value: 'BC-B: బొందిలి',
			valueEng: 'BC-B: Bondili'
		}, {
			code: '082',
			value: 'BC-B: ఆరె మరాఠీ, మరాఠా (బ్రాహ్మణేతరులు), అరకాలీలు మరియు సురభి నాటకాలవాళ్లు',
			valueEng: 'BC-B: Are Marathi, Maratha (Non- Brahmins), Arakalies and Surabhi Natakalavallu'
		}, {
			code: '083',
			value: 'BC-B: నీలి',
			valueEng: 'BC-B: Neeli'
		}, {
			code: '084',
			value: 'BC-B: బుడుబుంజల / భుంజ్వా / భద్భుంజ',
			valueEng: 'BC-B: Budubunjala / Bhunjwa / Bhadbhunja'
		}, {
			code: '085',
			value: 'BC-C: క్రిస్టియన్ మతంలోకి మారిన షెడ్యూల్ కులాల వారు మరియు వారి సంతానం',
			valueEng: 'BC-C: Scheduled Castes converts to Christianity and their progeny'
		}, {
			code: '086',
			value: 'BC-D: అరెకటిక, కటిక, అరె-సూర్యవంశీ',
			valueEng: 'BC-D: Arekatika, Katika, Are-Suryavamshi'
		}, {
			code: '087',
			value: 'BC-D: భట్రాజు',
			valueEng: 'BC-D: Bhatraju'
		}, {
			code: '088',
			value: 'BC-D: చిప్పోలు (మేరా)',
			valueEng: 'BC-D: Chippolu (Mera)'
		}, {
			code: '089',
			value: 'BC-D: హట్కర్',
			valueEng: 'BC-D: Hatkar'
		}, {
			code: '090',
			value: 'BC-D: జింగార్',
			valueEng: 'BC-D: Jingar'
		}, {
			code: '091',
			value: 'BC-D: కోష్టి',
			valueEng: 'BC-D: Koshti'
		}, {
			code: '092',
			value: 'BC-D: కాచి',
			valueEng: 'BC-D: Kachi'
		}, {
			code: '093',
			value: 'BC-D: సూర్యబలిజ (కళావంతుల), గణిక',
			valueEng: 'BC-D: Surya Balija (Kalavanthula), Ganika'
		}, {
			code: '094',
			value: 'BC-D: కృష్ణబలిజ (దాసరి, బుక్క)',
			valueEng: 'BC-D: Krishnabalija (Dasari, Bukka)'
		}, {
			code: '095',
			value: 'BC-D: మధుర',
			valueEng: 'BC-D: Mathura'
		}, {
			code: '096',
			value: 'BC-D: మాలి (బారె, బరాయి, మరార్ మరియు తంబోలి)',
			valueEng: 'BC-D: Mali (Bare, Barai, Marar and Tamboli)'
		}, {
			code: '097',
			value: 'BC-D: ముదిరాజ్, ముత్రాసి, తెనుగోల్లు',
			valueEng: 'BC-D: Mudiraj, Mutrasi, Tenugollu'
		}, {
			code: '098',
			value: 'BC-D: మున్నూరుకాపు',
			valueEng: 'BC-D: Munnurukapu'
		}, {
			code: '099',
			value: 'BC-D: పస్సి',
			valueEng: 'BC-D: Passi'
		}, {
			code: '100',
			value: 'BC-D: రంగరేజ్ లేదా భావసార క్షత్రియ',
			valueEng: 'BC-D: Rangarez or Bhavasara Kshatriya'
		}, {
			code: '101',
			value: 'BC-D: సాధుచెట్టి',
			valueEng: 'BC-D: Sadhuchetty'
		}, {
			code: '102',
			value: 'BC-D: సాతాని (చత్తద శ్రీవైష్ణవ)',
			valueEng: 'BC-D: Satani (Chattadasrivaishnava)'
		}, {
			code: '103',
			value: 'BC-D: తమ్మలి (బ్రాహ్మణేతరులు) (శూద్ర కులం)',
			valueEng: 'BC-D: Tammali (Non-Brahmins) (Shudra caste)'
		}, {
			code: '104',
			value: 'BC-D: ఉప్పర లేదా సాగర',
			valueEng: 'BC-D: Uppara or Sagara'
		}, {
			code: '105',
			value: 'BC-D: వంజర (వంజరి)',
			valueEng: 'BC-D: Vanjara (Vanjari)'
		}, {
			code: '106',
			value: 'BC-D: యాదవ (గొల్ల)',
			valueEng: 'BC-D: Yadava (Golla)'
		}, {
			code: '107',
			value: 'BC-D: ఆరె, ఆరెవాళ్ళు మరియు ఆరోల్లు',
			valueEng: 'BC-D: Are, Arevallu and Arollu'
		}, {
			code: '108',
			value: 'BC-D: అయ్యరాక',
			valueEng: 'BC-D: Ayyaraka'
		}, {
			code: '109',
			value: 'BC-D: నగరలు',
			valueEng: 'BC-D: Nagaralu'
		}, {
			code: '110',
			value: 'BC-D: అఘముడియన్, అఘముడియార్, ఆగముదివెల్లాలర్ మరియు ఆగముడిముదలియార్ (తుళువతో సహా | వెల్లలాస్)',
			valueEng: 'BC-D: Aghamudian, Aghamudiar, Agamudivellalar and Agamudimudaliar (including Thuluva | Vellalas)'
		}, {
			code: '111',
			value: 'BC-D: సొంది / సుంది',
			valueEng: 'BC-D: Sondi / Sundi'
		}, {
			code: '112',
			value: 'BC-D: వరాల',
			valueEng: 'BC-D: Varala'
		}, {
			code: '113',
			value: 'BC-D: సిష్టకరణం',
			valueEng: 'BC-D: Sistakaranam'
		}, {
			code: '114',
			value: 'BC-D: లక్కమారికాపు',
			valueEng: 'BC-D: Lakkamarikapu'
		}, {
			code: '115',
			value: 'BC-D: వీరశైవ లింగాయత్ / లింగబలిజ',
			valueEng: 'BC-D: Veerashaiva Lingayat / Lingabalija'
		}, {
			code: '116',
			value: 'BC-D: కుర్మి',
			valueEng: 'BC-D: Kurmi'
		}, {
			code: '117',
			value: 'BC-D: అహీర్ / అహీర్ యాదవ్',
			valueEng: 'BC-D: Aheer / Aheer Yadav'
		}, {
			code: '118',
			value: 'BC-D: Govili / Govlii / Gouli / Gavli',
			valueEng: 'BC-D: గోవిలి / గొవ్లీ / గౌలి / గవ్లీ'
		}, {
			code: '119',
			value: 'BC-D: కుళ్లకడగి / కుల్లెకడిగి / చిట్టెపు',
			valueEng: 'BC-D: KullaKadagi / KulleKadigi / Chittepu'
		}, {
			code: '120',
			value: 'BC-D: సరోల్లు / సోమ వంశ క్షత్రియ',
			valueEng: 'BC-D: Sarollu / Soma Vamsha Kshatriya'
		}, {
			code: '121',
			value: 'BC-E: అచ్చుకట్టలవాండ్లు, సింగలి, సింగంవాళ్లు, అచ్చుపనివాళ్లు, అచ్చుకట్టువారు, అచ్చుకట్లవాండ్లు',
			valueEng: 'BC-E: Achchukattalavandlu, Singali, Singamvallu, Achchupanivallu, Achchukattuvaru, Achukatlavandlu'
		}, {
			code: '122',
			value: 'BC-E: అత్తరు సాయిబులు, అత్తరోళ్లు',
			valueEng: 'BC-E: Attar Saibulu, Attarollu'
		}, {
			code: '123',
			value: `BC-E: ధోబీ ముస్లిం / ముస్లిం ధోబీ / ధోబీ ముసల్మాన్, తుర్కా చక్లా లేదా తుర్కా సకల, తురక చాకలి, 
				తులుక్క వన్నన్, త్సకలాలు, సకలాలు లేదా చాకలాలు, ముస్లిం రజకులు`,
			valueEng: `BC-E: Dhobi Muslim / Muslim Dhobi / Dhobi Musalman, Turka Chakla or Turka Sakala, 
				Turaka Chakali, Tulukka Vannan, Tsakalas, Sakalas or Chakalas, Muslim Rajakas`
		}, {
			code: '124',
			value: 'BC-E: ఫకీర్, ఫకీర్ బుడ్బుడ్కి, ఘంటి ఫకీర్, ఘంటా ఫకీర్లు, తురక బుడ్బుడ్కి, దర్వేష్, ఫకీర్',
			valueEng: 'BC-E: Faqir, Fhakir Budbudki, Ghanti Fhakir, Ghanta Fhakirlu, Turaka Budbudki, Darvesh, Fakeer'
		}, {
			code: '125',
			value: 'BC-E: గరడి ముస్లిం, గరడి సాయిబులు, పాములవాళ్లు, కని-కట్టువాళ్లు, గారడోళ్లు, గరడిగ',
			valueEng: 'BC-E: Garadi Muslim, Garadi Saibulu, Pamulavallu, Kani-Kattuvallu, Garadollu, Garadiga'
		}, {
			code: '126',
			value: 'BC-E: గోసంగి ముస్లిం, ఫకీర్ సాయెబులు',
			valueEng: 'BC-E: Gosangi Muslim, Phakeer Sayebulu'
		}, {
			code: '127',
			value: 'BC-E: గుడ్డి ఎలుగు వాళ్ళు, ఎలుగు బంటు వాళ్ళు, ముసల్మాన్ కీలు గుర్రం వాళ్ళు',
			valueEng: 'BC-E: Guddi Eluguvallu, Elugu Bantuvallu, Musalman Keelu Gurralavallu'
		}, {
			code: '128',
			value: 'BC-E: హజమ్, నాయి, నాయి ముస్లిం, నవిద్',
			valueEng: 'BC-E: Hajam, Nai, Nai Muslim, Navid'
		}, {
			code: '129',
			value: 'BC-E: లబ్బి, లబ్బాయి,లబ్బొన్, లబ్బ',
			valueEng: 'BC-E: Labbi, Labbai, Labbon, Labba'
		}, {
			code: '130',
			value: 'BC-E: పకీర్ల, బోరేవాలె, దీర ఫకీర్లు, బొంతల',
			valueEng: 'BC-E: Pakeerla, Borewale, Deera Phakirlu, Bonthala'
		}, {
			code: '131',
			value: 'BC-E: ఖురేషి, కురేషి / ఖురేషి, ఖాసబ్, మరాటీ ఖాసబ్, ముస్లిం కటిక, ఖతిక్ ముస్లిం',
			valueEng: 'BC-E: Qureshi, Kureshi / Khureshi, Khasab, Marati Khasab, Muslim Katika, Khatik Muslim'
		}, {
			code: '132',
			value: 'BC-E: షేక్ / షేక్',
			valueEng: 'BC-E: Shaik / Sheikh'
		}, {
			code: '133',
			value: 'BC-E: సిద్ది, యాబా, హబ్షి, జాసి',
			valueEng: 'BC-E: Siddi, Yaba, Habshi, Jasi'
		}, {
			code: '134',
			value: 'BC-E: తురక కాశ, కక్కుకొత్తె జింకా సాయిబులు, చక్కితకనేవాలె, తెరుగాడు గొంతలవారు, తిరుగటిగంట్ల, రోళ్లకు కక్కు కొట్టేవారు, పత్తర్ పోడులు, చక్కెతకరే, తురక కాషా',
			valueEng: 'BC-E: Turaka Kasha, Kakkukotte Zinka Saibulu, Chakkitakanevale, Terugadu Gontalavaru, Thirugatigantla, Rollaku Kakku Kottevaru, Pattar Phodulu, Chakketakare, Thuraka Kasha'
		}]
	},
	'7-OC': {
		number: '7-OC',
		question: 'ఇతర్ కులాలు (Other Castes)',
		questionEng: 'Other Castes',
		entries: [{
			code: '001',
			value: 'అయ్యంగార్లు / అయ్యర్',
			valueEng: 'Ayyangars / Iyer '
		}, {
			code: '002',
			value: 'బ్రాహ్మణులు',
			valueEng: 'Brahmins'
		}, {
			code: '003',
			value: 'బౌద్ధులు',
			valueEng: 'Buddhists'
		}, {
			code: '004',
			value: 'క్రిష్టియన్లు (బిసి-సీ లో లేని వారు)',
			valueEng: 'Christian (other than BC-C)'
		}, {
			code: '005',
			value: 'జైనులు',
			valueEng: 'Jains'
		}, {
			code: '006',
			value: 'కమ్మ / చౌదరి',
			valueEng: 'Kamma / Chowdary'
		}, {
			code: '007',
			value: 'కాపు / నాయుడు',
			valueEng: 'Kapu / Naidu'
		}, {
			code: '008',
			value: 'కరణం',
			valueEng: 'Karanam'
		}, {
			code: '009',
			value: 'కోమటి / వైశ్య / గుప్తా / శెట్టి',
			valueEng: 'Komati / Vaishya / Gupta / Shetti'
		}, {
			code: '010',
			value: 'క్షత్రియ / రాజులు',
			valueEng: 'Kshathriya / Raju'
		}, {
			code: '011',
			value: 'లింగాయత్ లు',
			valueEng: 'Lingayat'
		}, {
			code: '012',
			value: 'మార్వాడీలు',
			valueEng: 'Marwadies'
		}, {
			code: '013',
			value: 'ముస్లిం లు (బిసి-ఈ లో లేని వారు)',
			valueEng: 'Muslims (other than BC-E)'
		}, {
			code: '014',
			value: 'పట్నాయక్ లు',
			valueEng: 'Patnayaks'
		}, {
			code: '015',
			value: 'రెడ్డి',
			valueEng: 'Reddy'
		}, {
			code: '016',
			value: 'సిక్కులు',
			valueEng: 'Sikhs'
		}, {
			code: '017',
			value: 'వర్మ',
			valueEng: 'Varma'
		}, {
			code: '018',
			value: 'వెలమ',
			valueEng: 'Velama'
		}, {
			code: '000',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'10': {
		number: '10',
		question: 'మాతృ భాష వివరాలు తెలియజేయండి.',
		questionEng: 'Give details of mother tongue.',
		entries: [{
			code: '01',
			value: 'తెలుగు',
			valueEng: 'Telugu'
		}, {
			code: '02',
			value: 'హింది',
			valueEng: 'Hindi'
		}, {
			code: '03',
			value: 'ఉర్దూ',
			valueEng: 'Urdu'
		}, {
			code: '04',
			value: 'లంబాడి / గౌరు బోలి',
			valueEng: 'Lambadi / Gauru Boli'
		}, {
			code: '05',
			value: 'కోయ',
			valueEng: 'Koya'
		}, {
			code: '06',
			value: 'తమిళం',
			valueEng: 'Tamil'
		}, {
			code: '07',
			value: 'కన్నడ',
			valueEng: 'Kannada'
		}, {
			code: '08',
			value: 'మలయాళం',
			valueEng: 'Malayalam'
		}, {
			code: '09',
			value: 'గుజరాతీ',
			valueEng: 'Gujarati'
		}, {
			code: '10',
			value: 'మరాఠీ',
			valueEng: 'Marathi'
		}, {
			code: '11',
			value: 'పంజాబీ',
			valueEng: 'Punjabi'
		}, {
			code: '12',
			value: 'రాజస్థానీ',
			valueEng: 'Rajasthani'
		}, {
			code: '13',
			value: 'ఒడియా',
			valueEng: 'Odia'
		}, {
			code: '14',
			value: 'బెంగాలీ',
			valueEng: 'Bengali'
		}, {
			code: '15',
			value: 'అస్సామీ',
			valueEng: 'Assamese'
		}, {
			code: '16',
			value: 'గోండు',
			valueEng: 'Gond'
		}, {
			code: '17',
			value: 'కోలమ్',
			valueEng: 'Colum'
		}, {
			code: '18',
			value: 'తోటి',
			valueEng: 'Toti'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'13': {
		number: '13',
		question: 'మీరు దివ్యాంగులా?',
		questionEng: 'Are you disabled?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'కాదు',
			valueEng: 'No'
		}]
	},
	'14': {
		number: '14',
		question: 'వైవాహిక స్థితిని వ్రాయండి.',
		questionEng: 'Write Marital Status.',
		entries: [{
			code: '01',
			value: 'పెళ్లి అయ్యింది',
			valueEng: 'Married'
		}, {
			code: '02',
			value: 'పెళ్లి కాలేదు',
			valueEng: 'Unmarried'
		}, {
			code: '03',
			value: 'భర్త చనిపోయిన వారు (వితంతువు)',
			valueEng: 'Husband Died (Widow)'
		}, {
			code: '04',
			value: 'భార్య చనిపోయిన వారు',
			valueEng: 'Wife died'
		}, {
			code: '05',
			value: 'విడాకులు పొందిన వారు',
			valueEng: 'Divorced'
		}, {
			code: '06',
			value: 'ఒంటరి మహిళ',
			valueEng: 'Single woman'
		}, {
			code: '07',
			value: 'విడిగా ఉంటున్నవారు',
			valueEng: 'Living separately'
		}]
	},
	'15': {
		number: '15',
		question: 'పాఠశాల రకం వివరాలు పేర్కొనండి.',
		questionEng: 'Specify the details of the type of school.',
		entries: [{
			code: '01',
			value: 'కేంద్ర ప్రభుత్వ',
			valueEng: 'Central government'
		}, {
			code: '02',
			value: 'రాష్ట్ర ప్రభుత్వ',
			valueEng: 'State government'
		}, {
			code: '03',
			value: 'గురుకులాలు',
			valueEng: 'Gurukulas'
		}, {
			code: '04',
			value: 'ప్రైవేట్-ఎయిడెడ్',
			valueEng: 'Privately aided'
		}, {
			code: '05',
			value: 'ప్రైవేట్',
			valueEng: 'Private'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'17': {
		number: '17',
		question: 'అత్యున్నత విద్యార్హత వివరాలను పేర్కొనండి.',
		questionEng: 'Mention the details of the highest educational qualification.',
		entries: [{
			code: '01',
			value: 'నిరక్షరాస్యులు',
			valueEng: 'Illiterate'
		}, {
			code: '02',
			value: 'చదువుకోని అక్షరాస్యులు',
			valueEng: ''
		}, {
			code: '03',
			value: 'ప్రాథమిక (ప్రైమరీ) (1-5 వ తరగతి)',
			valueEng: 'Primary (Class 1-5)'
		}, {
			code: '04',
			value: 'ప్రాథమికోన్నత (అప్పర్ ప్రైమరీ) (6-7 వ తరగతి)',
			valueEng: 'Upper Primary (Class 6-7)'
		}, {
			code: '05',
			value: 'ఉన్నత (హై స్కూల్) (8-9వ తరగతి)',
			valueEng: 'High School (Class 8-9)'
		}, {
			code: '06',
			value: 'ఎస్.ఎస్.సి (10వ తరగతి)',
			valueEng: 'SSC (Class 10)'
		}, {
			code: '07',
			value: 'ఇంటర్మీడియట్',
			valueEng: 'Intermediate'
		}, {
			code: '08',
			value: 'పాలిటెక్నిక్',
			valueEng: 'Polytechnic'
		}, {
			code: '09',
			value: 'ఐ.ట్.ఐ.',
			valueEng: 'I.T.I.'
		}, {
			code: '10',
			value: 'సాధారణ డిగ్రీ (బిఏ, బిఎస్సి, బికాం, etc.)',
			valueEng: 'General Degree (BA, BSc, BCom, etc.)'
		}, {
			code: '11',
			value: 'సాధారణ పోస్ట్ గ్రాడ్యుయేట్ (ఎంఏ, ఎంఎస్సి, ఎంకామ్, etc.)',
			valueEng: 'General Post Graduate (MA, MSc, M.Com, etc.)'
		}, {
			code: '12',
			value: 'డిగ్రీ (అగ్రికల్చర్, వెటర్నరీ)',
			valueEng: 'Degree (Agriculture, Veterinary)'
		}, {
			code: '13',
			value: 'పోస్ట్ గ్రాడ్యుయేట్ (అగ్రికల్చర్, వెటర్నరీ)',
			valueEng: 'Post Graduate (Agriculture, Veterinary)'
		}, {
			code: '14',
			value: 'సాంకేతిక డిగ్రీ (బిఈ / బి.టెక్)',
			valueEng: 'Technical Degree (BE / B.Tech)'
		}, {
			code: '15',
			value: 'డి. ఫార్మసీ',
			valueEng: 'D. Pharmacy'
		}, {
			code: '16',
			value: 'బి. ఫార్మసీ',
			valueEng: 'B. Pharmacy'
		}, {
			code: '17',
			value: 'ఎం.ఫార్మసీ',
			valueEng: 'M. Pharmacy'
		}, {
			code: '18',
			value: 'సాంకేతిక పోస్ట్ గ్రాడ్యుయేట్ (ఎంఈ / ఎం.టెక్ / ఎంఎస్)',
			valueEng: 'Technical Post Graduate (ME / M.Tech / MS)'
		}, {
			code: '19',
			value: 'డిగ్రీ (న్యాయ విద్య)',
			valueEng: 'Degree (Legal Education)'
		}, {
			code: '20',
			value: 'పోస్ట్ గ్రాడ్యుయేట్ (న్యాయ విద్య)',
			valueEng: 'Post Graduate (Legal Education)'
		}, {
			code: '21',
			value: 'ఎంబిబిఎస్ / బిడిఎస్ / ఆయుష్ etc.',
			valueEng: 'MBBS / BDS / AYUSH etc.'
		}, {
			code: '22',
			value: 'ఎండి / ఎంఎస్ / ఎండిఎస్ etc.',
			valueEng: 'MD /MS /MDS etc.'
		}, {
			code: '23',
			value: 'ఎంబిఏ / ఎంసిఏ',
			valueEng: 'MBA / MCA'
		}, {
			code: '24',
			value: 'పిహెచ్డి',
			valueEng: 'PhD'
		}, {
			code: '25',
			value: 'సిఏ',
			valueEng: 'CA'
		}, {
			code: '26',
			value: 'సిఎల్ఐసి / బిఎల్ఐసి / ఎంఎల్ఐసి',
			valueEng: 'CLIC / BLIC / MLIC'
		}, {
			code: '27',
			value: 'బిసిజే / ఎంసిజే / జర్న లిజం',
			valueEng: 'BCJ / MCJ / Journalism'
		}, {
			code: '28',
			value: 'డిఈడి / బిఈడి / ఎంఈడి',
			valueEng: 'D.Ed. / B.Ed. / M.Ed.'
		}, {
			code: '29',
			value: 'హోటల్ మేనేజ్మెంట్',
			valueEng: 'Hotel Management'
		}, {
			code: '30',
			value: 'సోషల్ వర్క్',
			valueEng: 'Social work'
		}, {
			code: '31',
			value: 'నర్సింగ్',
			valueEng: 'Nursing'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'18': {
		number: '18',
		question: 'అత్యున్నత విద్యార్హత ఏ మాధ్యమం లో చదివారో వ్రాయండి.',
		questionEng: 'Write the medium in which the highest qualification was studied.',
		entries: [{
			code: '01',
			value: 'తెలుగు',
			valueEng: 'Telugu'
		}, {
			code: '02',
			value: 'ఇంగ్లిష్',
			valueEng: 'English'
		}, {
			code: '03',
			value: 'ఉర్దు',
			valueEng: 'Urdu'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Other'
		}]
	},
	'19': {
		number: '19',
		question: 'మీరు ప్రస్తుతం ఏం పని చేస్తున్నారో వ్రాయండి.',
		questionEng: 'Write down where you are currently working.',
		entries: [{
			code: '01',
			value: 'వృత్తి / ఉద్యోగం',
			valueEng: 'Occupation / Job'
		}, {
			code: '02',
			value: 'స్వయం ఉపాది',
			valueEng: 'Self Employed'
		}, {
			code: '03',
			value: 'వ్యాపారం / రియల్ ఎస్టేట్ / పారిశ్రామిక వేత్త',
			valueEng: 'Business / Real Estate / Industrialist'
		}, {
			code: '04',
			value: 'రోజువారీ వేతన కార్మికులు',
			valueEng: 'Daily wage workers'
		}, {
			code: '05',
			value: 'పదవీ విరమణ చేసిన ఉద్యోగి',
			valueEng: 'Retired employee'
		}, {
			code: '06',
			value: 'విద్యార్థి',
			valueEng: 'Student'
		}, {
			code: '07',
			value: 'గృహిణి',
			valueEng: 'Housewife'
		}, {
			code: '08',
			value: 'చిన్న వయస్సు (6 సంవత్సరాల లోపల)',
			valueEng: 'Young age (under 6 years)'
		}, {
			code: '09',
			value: 'ఏ పనీ చెయ్యడం లేదు',
			valueEng: 'Not doing any work'
		}, {
			code: '10',
			value: 'అద్దె / భృతి / ఆధారిత డబ్బు గ్రహీతలు',
			valueEng: 'Recipients of rent / gratuity / dependent money'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'20': {
		'number': '20',
		'question': 'కాలమ్ నెం. 19 వృత్తి / ఉద్యోగం అయితే ప్రస్తుత వృత్తి / ఉద్యోగ వివరాలు తెలియజేయండి.',
		questionEng: 'If in Column no. 19 Occupation is selected, give details of present occupation.',
		'entries': [{
			code: '01',
			value: 'అఖిల భారత / సివిల్ సర్వీసులు (IAS, IPS, IFS, IRS, etc.,)',
			valueEng: 'All India / Civil Services (IAS, IPS, IFS, IRS, etc.,)'
		}, {
			code: '02',
			value: 'ఇత్ర్ కేంద్ర ప్రభుత్వ ఉద్యోగ',
			valueEng: 'Another central government job'
		}, {
			code: '03',
			value: 'రాష్ట్ర గ్రూప్-1 / గ్రూప్-2 అధికారి',
			valueEng: 'State Group-1 / Group-2 Officer'
		}, {
			code: '04',
			value: 'ఇతర రాష్ట్ర ప్రభుత్వ ఉద్యోగ',
			valueEng: 'Other State Government Job'
		}, {
			code: '05',
			value: 'న్యాయమూర్తి (జడ్జ్)',
			valueEng: 'Judge'
		}, {
			code: '06',
			value: 'న్యాయవాది (అడ్వకేట్)',
			valueEng: 'Advocate'
		}, {
			code: '07',
			value: 'రక్షణ శాఖ (ఆర్మీ / నేవీ / ఎయిర్ ఫోర్స్ డే)',
			valueEng: 'Department of Defense (Army / Navy / Air Force)'
		}, {
			code: '08',
			value: 'డాక్టర్ / సర్జన్ ',
			valueEng: 'Doctor / Surgeon'
		}, {
			code: '09',
			value: 'ప్రభుత్వ ఉపాధ్యాయులు / లెక్చరర్ / ప్రొఫెసర్',
			valueEng: 'Government Teachers / Lecturer / Professor'
		}, {
			code: '10',
			value: 'ప్రైవేట్ ఉపాధ్యాయుడు / లెక్చరర్ / ప్రొఫెసర్ ',
			valueEng: 'Private Teacher / Lecturer / Professor'
		}, {
			code: '11',
			value: 'కేంద్ర / రాష్ట్ర ప్రభుత్వ రంగ సంస్థల ఉద్యోగి',
			valueEng: 'Employee of Central / State Public Sector Undertakings'
		}, {
			code: '12',
			value: 'ప్రభుత్వ బ్యాంక్ ఉద్యోగి ',
			valueEng: 'Government Bank Employee'
		}, {
			code: '13',
			value: 'ప్రైవేట్ బ్యాంక్ ఉద్యోగి ',
			valueEng: 'Private Bank Employee'
		}, {
			code: '14',
			value: 'ఒప్పంద ఉద్యోగులు',
			valueEng: 'Contract employees'
		}, {
			code: '15',
			value: 'ఆంగన్వాడీ / ఆశా వర్కర్లు ',
			valueEng: 'Anganwadi / ASHA workers'
		}, {
			code: '16',
			value: 'అవుట్ సోర్సింగ్ ',
			valueEng: 'Outsourcing'
		}, {
			code: '17',
			value: 'ప్రైవేట్ ఉద్యోగులు',
			valueEng: 'Private employees'
		}, {
			code: '18',
			value: 'వ్యవసాయం',
			valueEng: 'Agriculture'
		}, {
			code: '19',
			value: 'సాఫ్ట్ వేర్డ / బిపిఓ / కాల్ సెంటర్డ',
			valueEng: 'Software / BPO / Call Center'
		}, {
			code: '00', 
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	}, 
	'21': {
		'number': '21',
		'question': `కాలమ్ నెం. 19 లో 'స్వయం ఉపాధి' అయితే ప్రస్తుత స్వయం ఉపాది వివరాలను తెలియజేయండి.`,
		questionEng: `If in Column no. 19, 'Self-Employed' is selected, then give details of current self-employment`,
		'entries': [{
			code: '01',
			value: 'కాంట్రాక్టర్',
			valueEng: 'Contractor'
		}, {
			code: '02',
			value: 'బిల్డర్',
			valueEng: 'Builder'
		}, {
			code: '03',
			value: 'సినిమా నిర్మాత / దర్శకుడు / నటీనటులు',
			valueEng: 'Movie Producer / Director / Actors'
		}, {
			code: '04',
			value: 'క్రీడాకారులు',
			valueEng: 'Sportsperson'
		}, {
			code: '05',
			value: 'ఆర్కిటెక్ట్ / ఇంజనీర్',
			valueEng: 'Architect / Engineer'
		}, {
			code: '06',
			value: 'గృహ ఉపకరణాల తయారీ (కొవ్వొత్తుల, బొమ్మలు, అగర్బత్తి, అగ్గిపెట్టె మొదలైనవి.)',
			valueEng: 'Manufacture of household appliances (candles, toys, incense, matches etc.)'
		}, {
			code: '07',
			value: 'వ్యవసాయ ఉపకరణాల తయారీ (వ్యవసాయ పనిముట్లు మొదలైనవి.)',
			valueEng: 'Manufacture of agricultural implements (agricultural implements etc.)'
		}, {
			code: '08',
			value: 'కళాకారులు (సంగీత, వాయిద్య, నాటక, గానం, నృత్యం, చిత్రకారులు మొదలైనవి.)',
			valueEng: 'Artists (music, instrumental, drama, singing, dancing, painters etc.)'
		}, {
			code: '09',
			value: 'వాస్తు, జ్యోతిష్యం, మతపరమైన సేవలు',
			valueEng: 'Vastu, astrology, religious services'
		}, {
			code: '10',
			value: 'పురోహితులు, మౌలానా, చర్చి పాస్టర్',
			valueEng: 'Priests, Maulana, Church Pastor'
		}, {
			code: '11',
			value: `వ్యాపారులు (టీ, జ్యూస్, టేకరీ, మొబైల్, పూలు, పండ్లు, కాటరింగ్, రిపేరింగ్, చెప్పులు, బట్టలు, 
				నిర్మాణ సామగ్రి, పెయింటింగ్, ఎరువులు, ఫైర్ వుడ్ డిపో, ఫర్నీచర్ తయారీ, చెప్పుల తయారీ, ధాన్యం, ఇనుము, 
				ఉక్కు, ఆభరణాలు, క్రీడా మొదలైన దుకాణాలు, హోటల్ పరిశ్రమలు, రేషన్ షాపు, మొ..)`,
			valueEng: `Traders (Tea, Juice, Bakery, Mobile, Flowers, Fruits, Catering, Repairing, Shoes, 
				Clothes, Construction Materials, Painting, Fertilizers, Fire Wood Depot, Furniture Manufacturing, 
				Shoe Manufacturing, Grain, Iron, Steel, Jewellery, Sports etc. Shops , hotel industries, ration shop, etc.)`
		}, {
			code: '12',
			value: 'కిరాణా, జనరల్ దుకాణాలు (కిరాణా దుకాణం, కూల్ డ్రింక్స్ షాపు, ఇతరములు)',
			valueEng: 'Grocery, General Stores (grocery store, cool drinks shop, etc.)'
		}, {
			code: '13',
			value: 'ఎలక్ట్రానిక్, ఎలక్ట్రిక్ వస్తువులు అమ్మే దుకాణాలు',
			valueEng: 'Shops selling electronic and electrical goods'
		}, {
			code: '14',
			value: 'స్టేషనరీ, జిరాక్స్, బుక్ ప్రింటింగ్ వ్యాపారం',
			valueEng: 'Stationery, xerox, book printing business'
		}, {
			code: '15',
			value: 'మనీ లెండరింగ్, పెట్రోల్ బంక్, రైస్ మిల్లు, ట్రావెల్ ఏజెన్సీ, చిట్టీల వ్యాపారం',
			valueEng: 'Money Lending, Petrol Pump, Rice Mill, Travel Agency, Chitty Trading'
		}, {
			code: '16',
			value: 'బ్యూటీపార్లర్ / డ్రై క్లీనింగ్ / టైలరింగ్ / ఫ్యాన్సీ స్టోర్ / బోటిక్',
			valueEng: 'Beauty Parlour / Dry Cleaning/Tailoring / Fancy Store / Boutique'
		}, {
			code: '17',
			value: 'నిర్మాణ రంగం (ఇటుకల తయారీ / పెంకులు / సిమెంట్ / ప్లంబర్ / కార్పెంటర్)',
			valueEng: 'Construction Sector (brick making / shells / cement / plumber / carpenter)'
		}, {
			code: '18',
			value: 'ఏజెంట్లు (ఇన్స్యూరెన్స్, ఆర్టీఏ, మెడికల్ / పాన్ బ్రోకర్లు)',
			valueEng: 'Agents (Insurance, RTA, Medical / Pawn Brokers)'
		}, {
			code: '19',
			value: 'పాడి / పౌల్ట్రీ / ఉద్యానవన',
			valueEng: 'Dairy / Poultry / Horticulture'
		}, {
			code: '20',
			value: 'చేపల పెంపకం / రొయ్యల పెంపకం / తేనెటీగల పెంపకం',
			valueEng: 'Fish Farming / Shrimp Farming / Bee Farming'
		}, {
			code: '21',
			value: 'రవాణా రంగంలో (డ్రైవర్లు, క్లీనర్లు, మెకానిక్, రవాణా ఆపరేటర్లు, బస్సు, టాక్సీ సర్వీసులు)',
			valueEng: 'In transport sector (drivers, cleaners, mechanic, transport operators, bus, taxi services)'
		}, {
			code: '22',
			value: 'టెక్నీషియన్ (ఎలక్ట్రికల్, ఎలక్ట్రానిక్, గృహ ఉపకరణాల టెక్నీషియన్)',
			valueEng: 'Technician (Electrical, Electronic, Home Appliances Technician)'
		}, {
			code: '23',
			value: 'చేతి పని ఉపాధి (చేనేత పని, బీడీఆకుల సేకరణ)',
			valueEng: 'Handicraft employment (handloom work, collection of beedi leaves)'
		}, {
			code: '24',
			value: 'కల్లు అమ్మకం, మద్యం షాపు, డ్రై క్లీనింగ్, లాండ్రి, వడ్రంగి',
			valueEng: 'Brick sale, Liquor shop, Dry cleaning, Laundry, Carpentry'
		}, {
			code: '25',
			value: 'మెడికల్ షాపు, మెడికల్ ల్యాబ్స్',
			valueEng: 'Medical Shop, Medical Labs'
		}, {
			code: '26',
			value: 'హోటల్ పరిశ్రమ (బోర్డింగ్, లాడ్జి, రెస్టారెంట్, ఫుడ్ కోర్టులు, కర్రీ పాయింటులు)',
			valueEng: 'Hotel Industry (Boarding, Lodge, Restaurant, Food Courts, Curry Points)'
		}, {
			code: '27',
			value: 'కుటీర పరిశ్రమ (చాపలు, బుట్టలు, కుట్లు, అల్లికలు, మెటల్ వ్యాపారం)',
			valueEng: 'Cottage industry (mats, baskets, knitting, weaving, metal trade)'
		}, {
			code: '28',
			value: 'మీడియా (ప్రింట్, ఎలక్ట్రానిక్, టివి, జర్నలిస్ట్, కెమెరా మాన్)',
			valueEng: 'Media (Print, Electronic, TV, Journalist, Cameraman)'
		}, {
			code: '29',
			value: 'స్వయం సహాయక బృందాలు (ఫుడ్ ప్రొసెసింగ్, టైలరింగ్, తోలు బొమ్మలు, మొ..)',
			valueEng: 'Self Help Groups (Food Processing, Tailoring, Leather Goods, etc.)'
		}, {
			code: '30',
			value: 'సేల్స్ (బట్టల వ్యాపారం, బ్యాంకులు, మాల్స్ మొ.)',
			valueEng: 'Sales (clothing business, banks, malls etc.)'
		}, {
			code: '31',
			value: 'వైద్య సేవలు (RMP, నర్సులు, కాంపొండర్లు, ప్రధమ చికిత్స క్లినిక్ లు, ఆరోగ్య కార్యకర్తలు)',
			valueEng: 'Medical services (RMP, nurses, compounders, first aid clinics, health workers)'
		}, {
			code: '32',
			value: 'డోర్ సర్వీస్ (డెలివరి బాయ్స్, పేపర్, కొరియర్ సర్వీస్ మొ||)',
			valueEng: 'Door Service (Delivery Boys, Paper, Courier Service etc.)'
		}, {
			code: '33',
			value: 'టాంగా / రిక్షా',
			valueEng: 'Tanga / Rickshaw'
		}, {
			code: '34',
			value: 'డీలర్స్ (వెహికల్స్, హోల్ సేల్, రిటైల్)',
			valueEng: 'Dealers (Vehicles, Wholesale, Retail)'
		}, {
			code: '35',
			value: 'డిజిటల్ మీడియా (Youtubers / Influencers / Content Creators / Content Managers)',
			valueEng: 'Digital Media (Youtubers / Influencers / Content Creators / Content Managers)'
		}, {
			code: '36',
			value: 'డెయిరీ ప్రాడక్ట్స్ (పాల అమ్మకం, సరఫరా, స్టోరేజి)',
			valueEng: 'Dairy Products (Sales, Supply, Storage of Milk)'
		}, {
			code: '37',
			value: 'విద్య రంగం (పాఠశాల, కళాశాల, ట్యూషన్, కోచింగ్ సెంటర్లు)',
			valueEng: 'Education Sector (School, College, Tuition, Coaching Centres)'
		}, {
			code: '38',
			value: 'అటవీ ఉత్పత్తుల సేకరణ',
			valueEng: 'Collection of forest produce'
		}, {
			code: '39',
			value: 'హాకర్లు (కూరగాయలు, తోపుడు బండ్లు, రోడ్ల మీద అమ్మకం)',
			valueEng: 'Hawkers (vegetables, bakery carts, roadside vendors)'
		}, {
			code: '40',
			value: 'లీగల్ సర్వీసులు / ప్రొఫెషనల్ సర్వీసులు / ఫైనాన్స్ సర్వీసులు',
			valueEng: 'Legal Services / Professional Services / Finance Services'
		}, {
			code: '41',
			value: 'సోషల్ ఆక్టివిటీస్ / ఎన్జిఓ / ప్రజా సేవ',
			valueEng: 'Social Activities / NGO / Public Service'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'22': {
		number: '22',
		question: `కాలమ్ నెం. 19 లో 'వ్యాపారం / రియల్ ఎస్టేట్ / పారిశ్రామిక వేత్త' అయితే వార్షిక టర్నోవర్ తెలియజేయండి`,
		questionEng: `In Column no. 19, in case of 'Business / Real Estate / Industrialist', indicate the annual turnover`,
		entries: [{
			code: '01',
			value: '25 లక్షల వరకు',
			valueEng: 'Below 25 lakhs'
		}, {
			code: '02',
			value: '26 లక్షలు – 50 లక్షలు',
			valueEng: '26 lakhs - 50 lakhs'
		}, {
			code: '03',
			value: '51 లక్షలు – 1.0 కోటి',
			valueEng: '51 lakhs - 1.0 crores'
		}, {
			code: '04',
			value: '1.1 కోట్లు – 3.0 కోట్లు ',
			valueEng: '1.1 crores - 3.0 crores'
		}, {
			code: '05',
			value: '3.1 కోట్లు – 5.0 కోట్లు',
			valueEng: '3.1 crores - 5.0 crores'
		}, {
			code: '06',
			value: '5.1 కోట్లు - 10.0 కోట్లు',
			valueEng: '5.1 crores - 10.0 crores'
		}, {
			code: '07',
			value: '10.1 కోట్లు - 20.0 కోట్లు',
			valueEng: '10.1 crores - 20.0 crores'
		}, {
			code: '08',
			value: '20.1 కోట్లు - 30.0 కోట్లు',
			valueEng: '20.1 crores - 30.0 crores'
		}, {
			code: '09',
			value: '30.1 కోట్లు - 40.0 కోట్లు',
			valueEng: '30.1 crores - 40.0 crores'
		}, {
			code: '10',
			value: '40.1 కోట్లు - 50.0 కోట్లు',
			valueEng: '40.1 crores - 50.0 crores'
		}, {
			code: '11',
			value: '50.1 కోట్లు - ఆ పైన',
			valueEng: 'Above 50.1 crores'
		}]
	},
	'23': {
		number: '23',
		question: 'కాలమ్ నెం. 19 లో రోజువారీ వేతన కార్మికులు అయితే ప్రస్తుతం పనిచేస్తున్న అసంఘటిత రంగం వివరాలను తెలియజేయండి.',
		questionEng: 'In Column no. 19, if daily wage workers is selected, give details of unorganized sector currently working.',
		entries: [{
			code: '01',
			value: 'వ్యవసాయ కూలీలు',
			valueEng: 'Agricultural laborers'
		}, {
			code: '02',
			value: 'భవన నిర్మాణ కార్మికులు',
			valueEng: 'Construction workers'
		}, {
			code: '03',
			value: 'బీడీ కార్మికులు',
			valueEng: 'Beedi workers'
		}, {
			code: '04',
			value: 'హోటల్ కార్మికులు',
			valueEng: 'Hotel workers'
		}, {
			code: '05',
			value: 'ఖార్కాన కార్మికులు',
			valueEng: 'Kharkana workers'
		}, {
			code: '06',
			value: 'ఉపాధి హామీ పథకం కూలీలు (MGNREGA)',
			valueEng: 'Employment Guarantee Scheme for Laborers (MGNREGA)'
		}, {
			code: '07',
			value: 'గని కూలీలు / కార్మికులు',
			valueEng: 'Miners / Labourers'
		}, {
			code: '08',
			value: 'హమాలీలు',
			valueEng: 'Porters'
		}, {
			code: '09',
			value: 'ఇటుక బట్టీ కూలీలు',
			valueEng: 'Brick kiln workers'
		}, {
			code: '10',
			value: 'ఇంటి పని వారు (house maids, helpers, cleaners)',
			valueEng: 'House workers'
		}, {
			code: '11',
			value: 'గిగ్ వర్కర్లు (స్వీగ్గి, జోమాటో, Ola, Uber సేవలు మొ||)',
			valueEng: 'Gig workers (Swiggy, Zomato, Ola, Uber services, etc.)'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	}, 
	'24': {
		number: '24',
		question: 'సాంప్రదాయ కుల వృత్తి వివరాలను తెలియజేయండి',
		questionEng: 'Give details of traditional caste occupation.',
		entries: [{
			code: '01',
			value: 'కుల వృత్తి లేదు',
			valueEng: 'No caste occupation'
		}, {
			code: '02',
			value: 'చేనేత కార్మికులు',
			valueEng: 'Handloom workers'
		}, {
			code: '03',
			value: 'కల్లుగీత కార్మికులు',
			valueEng: 'Stone masons'
		}, {
			code: '04',
			value: 'మత్స్యకారులు',
			valueEng: 'Fishermen'
		}, {
			code: '05',
			value: 'స్వర్ణకారులు',
			valueEng: 'Goldsmiths'
		}, {
			code: '06',
			value: 'మంగలి వారు',
			valueEng: 'Barbers'
		}, {
			code: '07',
			value: 'చాకలివారు',
			valueEng: 'Washermen'
		}, {
			code: '08',
			value: 'కుమ్మరి',
			valueEng: 'Potter'
		}, {
			code: '09',
			value: 'కమ్మరి',
			valueEng: 'Blacksmith'
		}, {
			code: '10',
			value: 'వడ్రంగి',
			valueEng: 'Carpenter'
		}, {
			code: '11',
			value: 'టైలర్',
			valueEng: 'Tailor'
		}, {
			code: '12',
			value: 'చెప్పులు కుట్టేవారు / చర్మకారులు',
			valueEng: 'Cobbler / Tanner'
		}, {
			code: '13',
			value: 'గొర్రెల / పశువుల కాపరులు',
			valueEng: 'Shepherd / Herdsmen'
		}, {
			code: '14',
			value: 'కళాకారులు (బైండ్ల వారు, హరికథలు, బుర్రకథలు, డప్పు, మొ.)',
			valueEng: 'Artists (Bindlas, Harikathas, Burrakathas, Dappu, etc.)'
		}, {
			code: '15',
			value: 'పశుపోషణ',
			valueEng: 'Animal husbandry'
		}, {
			code: '16',
			value: 'నీరడి / కాపలా దారులు',
			valueEng: 'Drainage'
		}, {
			code: '17',
			value: 'కటిక వృత్తి',
			valueEng: 'Katika profession'
		}, {
			code: '00',
			value: 'ఇతర సాంప్రదాయ కుల వృత్తి',
			valueEng: 'Other Traditional Caste Occupation'
		}]
	}, 
	'25': {
		number: '25',
		question: 'ప్రస్తుతం కుల వృత్తి లో కొనసాగుతున్నారా?',
		questionNum: 'Are you currently working in the case occumpation?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'లేదు',
			valueEng: 'No'
		}]
	},
	'26': {
		number: '26',
		question: 'కుల వృత్తి చేయుట వల్ల ఏమైనా వ్యాధులు వచ్చినట్లైతే వివరాలను తెలియజేయండి',
		questionEng: 'Give the details if any diseases due to caste profession.',
		entries: [{
			code: '01',
			value: 'ఏ వ్యాధి లేదు',
			valueEng: 'No disease'
		}, {
			code: '02',
			value: 'చర్మ వ్యాధులు',
			valueEng: 'Skin diseases'
		}, {
			code: '03',
			value: 'ఆస్తమా',
			valueEng: 'Asthma'
		}, {
			code: '04',
			value: 'శ్వాస కోశ వ్యాధులు',
			valueEng: 'Respiratory diseases'
		}, {
			code: '05',
			value: 'పేగు సంబంధ వ్యాధులు',
			valueEng: 'Intestinal diseases'
		}, {
			code: '06',
			value: 'అంధత్వం',
			valueEng: 'Blindness'
		}, {
			code: '07',
			value: 'ఎముకల సంబంధ వ్యాధులు',
			valueEng: 'Bone diseases'
		}, {
			code: '08',
			value: 'క్షయవ్యాధి',
			valueEng: 'Tuberculosis'
		}, {
			code: '09',
			value: 'దీర్ఘకాలిక వ్యాధులు',
			valueEng: 'Chronic diseases'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'27': {
		number: '27',
		question: 'జాబితాలో అందించిన పరిధుల ప్రకారం వార్షిక ఆదాయాన్ని తెల్పండి',
		questionEng: 'Indicate the annual income as per the ranges given in the list.',
		entries: [{
			code: '01',
			value: 'ఎలాంటి ఆదాయం లేదు',
			valueEng: 'No income'
		}, {
			code: '02',
			value: 'రూ. 50,000 లోపు ',
			valueEng: 'Under Rs. 50,000'
		}, {
			code: '03',
			value: 'రూ. 50,001 నుండి 1,00,000 ',
			valueEng: 'Rs. 50,001 to 1,00,000'
		}, {
			code: '04',
			value: 'రూ. 1,00,001 నుండి 1,50,000 ',
			valueEng: 'Rs. 1,00,001 to 1,50,000'
		}, {
			code: '05',
			value: 'రూ. 1,50,001 నుండి 2,00,000 ',
			valueEng: 'Rs. 1,50,001 to 2,00,000'
		}, {
			code: '06',
			value: 'రూ. 2,00,001 నుండి 5,00,000 ',
			valueEng: 'Rs. 2,00,001 to 5,00,000'
		}, {
			code: '07',
			value: 'రూ. 5,00,001 నుండి 10,00,000 ',
			valueEng: 'Rs. 5,00,001 to 10,00,000'
		}, {
			code: '08',
			value: 'రూ. 10,00,001 నుండి 15,00,000 ',
			valueEng: 'Rs. 10,00,001 to 15,00,000'
		}, {
			code: '09',
			value: 'రూ. 15,00,001 నుండి 20,00,000 ',
			valueEng: 'Rs. 15,00,001 to 20,00,000'
		}, {
			code: '10',
			value: 'రూ. 20,00,001 నుండి 25,00,000 ',
			valueEng: 'Rs. 20,00,001 to 25,00,000'
		}, {
			code: '11',
			value: 'రూ. 25,00,001 నుండి 50,00,000 ',
			valueEng: 'Rs. 25,00,001 to 50,00,000'
		}, {
			code: '12',
			value: 'రూ. 50,00,001 నుండి రూ. కోటి ',
			valueEng: 'Rs. 50,00,001 to Rs. 1 Crore'
		}, {
			code: '13',
			value: 'రూ. కోటి పైన ',
			valueEng: 'Above 1 Crore'
		}]
	},
	'28': {
		number: '28',
		question: 'మీరు ఆదాయపు పన్ను చెల్లింపుదారులా?',
		questionEng: 'Do you pay Income Tax?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'కాదు',
			valueEng: 'No'
		}]
	},
	'29': {
		number: '29',
		question: 'మీకు బ్యాంక్ ఖాతా ఉందా?',
		questionEng: 'Do you have a bank account?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'లేదు',
			valueEng: 'No'
		}]
	},
	'30': {
		number: '30',
		question: 'మీరు సొంత భూమి కలిగి ఉన్నారా?',
		questionEng: 'Do you own land?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'కాదు',
			valueEng: 'No'
		}]
	},
	'32': {
		number: '32',
		question: 'ధరణి పాస్బుక్ లేనట్లైతే కారణం.',
		questionEng: 'If there is no Dharani passbook, indicate the reason.',
		entries: [{
			code: '01',
			value: 'పట్టా సమస్య లేదు',
			valueEng: ''
		}, {
			code: '02',
			value: 'వారసత్వం లేదా మ్యూటేషన్ సమస్యలు',
			valueEng: 'Inheritance or mutation problems'
		}, {
			code: '03',
			value: 'పట్టా ఉంది కానీ దానిలో వివరాలు తప్పుగా వచ్చాయి',
			valueEng: 'There is patta certificate but the details are wrong'
		}, {
			code: '04',
			value: 'ధరణి వలన వచ్చిన పట్టా సమస్య',
			valueEng: 'Patta problem caused by Dharani'
		}, {
			code: '05',
			value: 'పట్టా ఉంది కానీ భూమి ఇతరుల అక్రమ ఆధీనంలో ఉంది',
			valueEng: 'The title is there but the land is in illegal possession of others'
		}, {
			code: '06',
			value: 'అసైన్డ్ భూమి ఇచ్చారు కానీ, పట్టా ఇవ్వలేదు',
			valueEng: 'Assigned land was given but no title was given'
		}, {
			code: '07',
			value: 'అసైన్డ్ భూమి ఇచ్చారు కానీ, అక్రమంగా ఇతరుల ఆధీనంలో ఉంది',
			valueEng: 'Assigned land was given but illegally occupied by others'
		}, {
			code: '08',
			value: 'అటవీ భూమి పై ROFR దరఖాస్తు చేసుకున్నాము కానీ సర్వే జరగలేదు',
			valueEng: 'We applied for ROFR on forest land but no survey was done'
		}, {
			code: '09',
			value: 'అటవీ భూమి పై ROFR సర్వే జరిగినది కానీ అటవీ హక్కు పత్రం (పట్టా) ఇవ్వలేదు',
			valueEng: 'ROFR survey of forest land was done but no forest title deed (patta) was issued'
		}, {
			code: '10',
			value: 'ప్రభుత్వ భూమి సాగు చేస్తున్నాం, అసైన్డ్ పట్టా కోరుతున్నాము',
			valueEng: 'We are cultivating government land, we are asking for an assigned title'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'39': {
		number: '39',
		question: 'నీటిపారుదల సౌకర్యాల వివరాలను పేర్కొనండి.',
		questionEng: 'Mention the details of irrigation facilities.',
		entries: [{
			code: '01',
			value: 'ప్రాజెక్ట్ కాలువ',
			valueEng: 'Project canal'
		}, {
			code: '02',
			value: 'లిఫ్ట్ ఇరిగేషన్',
			valueEng: 'Lift irrigation'
		}, {
			code: '03',
			value: 'చెరువు',
			valueEng: 'Pond / Cheruvu'
		}, {
			code: '04',
			value: 'బోర్ బావి',
			valueEng: 'Bore well'
		}, {
			code: '05',
			value: 'త్రవ్వకపు బావి',
			valueEng: 'Excavation well'
		}, {
			code: '06',
			value: 'వర్షాధారం',
			valueEng: 'Rainfall'
		}, {
			code: '00',
			value: 'ఇతర వనరులు',
			valueEng: 'Other resources'
		}]
	},
	'41': {
		number: '41',
		question: 'విద్య కోసం రిజర్వేషన్ విధానం ద్వారా ప్రవేశం పొందితే',
		questionEng: 'If admission is through reservation system for education',
		entries: [{
			code: '01',
			value: 'ఏ ప్రయోజనం పొందలేదు',
			valueEng: 'No benefit was obtained'
		}, {
			code: '02',
			value: 'డిగ్రీ',
			valueEng: 'Degree'
		}, {
			code: '03',
			value: 'డిప్లొమా',
			valueEng: 'Diploma'
		}, {
			code: '04',
			value: 'ఇంజనీరింగ్ / మెడికల్',
			valueEng: 'Engineering / Medical'
		}, {
			code: '05',
			value: 'పీజీ కోర్సులు',
			valueEng: 'PG Courses'
		}, {
			code: '06',
			value: 'ఇతర వృతి విద్య కోర్సులు',
			valueEng: 'Other vocational courses'
		}, {
			code: '07',
			value: 'ప్రభుత్వ హాస్టళ్లు / రెసిడెన్షియల్ పాఠశాలలు',
			valueEng: 'Government Hostels / Residential Schools'
		}, {
			code: '08',
			value: 'స్కాలర్షిప్ మంజూరు కోసం',
			valueEng: 'For grant of scholarship'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Other'
		}]
	},
	'42': {
		number: '42',
		question: 'ఉద్యోగం కోసం రిజర్వేషన్ నుండి పొందిన ప్రయోజనాలు తెలియజేయండి',
		questionEng: 'State the benefits derived from reservation for employment.',
		entries: [{
			code: '01',
			value: 'ఏ ప్రయోజనం పొందలేదు',
			valueEng: 'No benefit was obtained'
		}, {
			code: '02',
			value: `గ్రూప్ '1' ఉద్యోగం`,
			valueEng: `Group '1' Job`
		}, {
			code: '03',
			value: `గ్రూప్ '2' ఉద్యోగం`,
			valueEng: `Group '2' Job`
		}, {
			code: '04',
			value: `గ్రూప్ '3' ఉద్యోగం`,
			valueEng: `Group '3' Job`
		}, {
			code: '05',
			value: `గ్రూప్ '4' ఉద్యోగం`,
			valueEng: `Group '4' Job`
		}, {
			code: '06',
			value: 'జిల్లా నియామక కమిటీలు (DSC)',
			valueEng: 'District Selection Committees (DSC)'
		}, {
			code: '07',
			value: 'కేంద్ర ప్రభుత్వ ఉద్యోగులు',
			valueEng: 'Central Government Employees'
		}, {
			code: '08',
			value: 'ఇతర రాష్ట్ర ప్రభుత్వ ఉద్యోగులు',
			valueEng: 'Other State Government Employees'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'43(a,b,c)': {
		number: '43(a,b,c)',
		question: 'గత అయిదు సంవత్సరాలలో ప్రభుత్వం నుండి లబ్ది పొందిన పథకాల పేర్లు తెలియజేయండి (మూడు పథకాల వరకు నమోదు చెయ్యవచ్చు',
		questionEng: 'State the names of the schemes benefited by the government in the last five years (up to three schemes can be entered).',
		entries: [{
			code: '01',
			value: 'ఏ లబ్ది పొందలేదు',
			valueEng: 'No benefit received'
		}, {
			code: '02',
			value: 'కళ్యాణ లక్ష్మి / షాది ముబారక్',
			valueEng: 'Kalyana Lakshmi / Shaadi Mubarak'
		}, {
			code: '03',
			value: 'చేయూత పెన్షన్లు',
			valueEng: 'Cheyutha Pensions'
		}, {
			code: '04',
			value: 'రైతు భరోసా',
			valueEng: 'Rythu Bharosa'
		}, {
			code: '05',
			value: 'రైతు భీమా',
			valueEng: 'Rythu Bima'
		}, {
			code: '06',
			value: 'ఆరోగ్య శ్రీ',
			valueEng: 'Arogya Sri'
		}, {
			code: '07',
			value: 'ఉచిత వ్యవసాయ విద్యుత్',
			valueEng: 'Free agricultural electricity'
		}, {
			code: '08',
			value: 'ఉచిత గృహ విద్యుత్ (గృహ జ్యోతి)',
			valueEng: 'Free Home Electricity (Gruha Jyoti)'
		}, {
			code: '09',
			value: 'సబ్సిడీ ఎల్పిజి సిలిండర్ (మహాలక్ష్మి)',
			valueEng: 'Subsidized LPG Cylinder (Mahalakshmi)'
		}, {
			code: '10',
			value: 'మహిళలకు ఉచిత బస్సు ప్రయాణం (మహాలక్ష్మి)',
			valueEng: 'Free bus travel for women (Mahalakshmi)'
		}, {
			code: '11',
			value: 'ప్రభుత్వ ఇల్లు',
			valueEng: 'Government House'
		}, {
			code: '12',
			value: 'పంట ఋణ మాఫీ',
			valueEng: 'Crop loan waiver'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'44': {
		number: '44',
		question: 'మీరు SC/ST/BC/EWS కు చెందిన వారైతే ధృవీకరణ పత్రం పొందారా?',
		questionEng: 'Have you got the caste certificate if you belong to SC/ST/BC/EWS?',
		entries: [{
			code: '01',
			value: 'అవును',
			valueEng: 'Yes'
		}, {
			code: '02',
			value: 'కాదు',
			valueEng: 'No'
		}]
	},
	'45(a)': {
		number: '45(a)',
		question: 'ప్రజాప్రతినిధిగా ప్రస్తుతం పనిచేస్తున్న సభ్యత్వం వివరాలు తెలియజేయండి',
		questionEng: 'Give the details of the membership currently serving as a public representative.',
		entries: [{
			code: '01',
			value: 'ప్రజా ప్రతినిధిగా పనిచెయ్యట్లేదు',
			valueEng: 'Not working as a public representative'
		}, {
			code: '02',
			value: 'పార్లమెంట్ సభ్యులు (రాజ్య సభ)',
			valueEng: 'MP (Rajya Sabha)'
		}, {
			code: '03',
			value: 'పార్లమెంట్ సభ్యులు (లోక్ సభ)',
			valueEng: 'MP (Lok Sabha)'
		}, {
			code: '04',
			value: 'శాసన మండలి సభ్యులు',
			valueEng: 'MLC'
		}, {
			code: '05',
			value: 'శాసన సభ్యులు',
			valueEng: 'MLA'
		}, {
			code: '06',
			value: 'ZPTC సభ్యులు',
			valueEng: 'ZPTC Members'
		}, {
			code: '07',
			value: 'MPTC సభ్యులు',
			valueEng: 'MPTC Members'
		}, {
			code: '08',
			value: 'మున్సిపల్ కౌన్సిలర్ / కార్పొరేటర్',
			valueEng: 'Municipal Councillor / Corporator'
		}, {
			code: '09',
			value: 'సర్పంచ్',
			valueEng: 'Sarpanch'
		}, {
			code: '10',
			value: 'వార్డు సభ్యులు',
			valueEng: 'Ward members'
		}, {
			code: '00',
			value: '',
			valueEng: 'Others'
		}]
	},
	'45(b)': {
		number: '45(b)',
		question: 'ప్రజాప్రతినిధిగా గతంలో పనిచేసిన వివరాలు తెలియజేయండి',
		questionEng: 'Give the details of past work as public representative.',
		entries: [{
			code: '01',
			value: 'ప్రజా ప్రతినిధిగా గతం లో పనిచెయ్యలేదు',
			valueEng: 'Has not worked as a public representative in the past'
		}, {
			code: '02',
			value: 'పార్లమెంట్ సభ్యులు (రాజ్య సభ)',
			valueEng: 'MP (Rajya Sabha)'
		}, {
			code: '03',
			value: 'పార్లమెంట్ సభ్యులు (లోక్ సభ)',
			valueEng: 'MP (Lok Sabha)'
		}, {
			code: '04',
			value: 'శాసన మండలి సభ్యులు',
			valueEng: 'MLC'
		}, {
			code: '05',
			value: 'శాసన సభ్యులు',
			valueEng: 'MLA'
		}, {
			code: '06',
			value: 'ZPTC సభ్యులు',
			valueEng: 'ZPTC Members'
		}, {
			code: '07',
			value: 'MPTC సభ్యులు',
			valueEng: 'MPTC Members'
		}, {
			code: '08',
			value: 'మున్సిపల్ కౌన్సిలర్ / కార్పొరేటర్',
			valueEng: 'Municipal Councillor / Corporator'
		}, {
			code: '09',
			value: 'సర్పంచ్',
			valueEng: 'Sarpanch'
		}, {
			code: '10',
			value: 'వార్డు సభ్యులు',
			valueEng: 'Ward members'
		}, {
			code: '00',
			value: '',
			valueEng: 'Others'
		}]
	},
	'45(c)': {
		number: '45(c)',
		question: 'ప్రజాప్రతినిధిగా పని చేస్తే వివిధ పోస్టులలో కొనసాగిన మొత్తం వ్యవధిని పేర్కొనండి',
		questionEng: 'State the total period held in various posts if working as a public representative.',
		entries: [{
			code: '01',
			value: '5 సం.లోపు',
			valueEng: 'Within 5 years'
		}, {
			code: '02',
			value: '5 నుండి 10 సంవత్సరాలు',
			valueEng: '5 to 10 years'
		}, {
			code: '03',
			value: '10 నుండి 15 సంవత్సరాలు',
			valueEng: '10 to 15 years'
		}, {
			code: '04',
			value: '15 సంవత్సరాలకు పైన',
			valueEng: 'Above 15 years'
		}]
	},
	'46': {
		number: '46',
		question: 'నామినేటెడ్ / కార్పొరేషన్ / బోర్డ్ / కో-ఆపరేటివ్ సొసైటీ / ప్రభుత్వేతర సంస్థల లో సభ్యులు / సంఘ సభ్యుని వివరాలను తెలియజేయండి',
		questionEng: 'Give details of Nominated / Corporation / Board / Co-operative Society / Members of Non-Government Organizations / Community Member.',
		entries: [{
			code: '01',
			value: 'ఎలాంటి సభ్యత్వం లేదు',
			valueEng: 'No membership'
		}, {
			code: '02',
			value: 'సహకార సంఘ సభ్యులు',
			valueEng: 'Co-operative society members'
		}, {
			code: '03',
			value: 'బోర్డు / కార్పొరేషన్ సభ్యులు',
			valueEng: 'Members of a Board / Corporation'
		}, {
			code: '04',
			value: 'కమీషన్ సభ్యులు',
			valueEng: 'Members of a Commission'
		}, {
			code: '05',
			value: 'కార్మిక సంఘ సభ్యులు',
			valueEng: 'Labor union members'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'47(a)': {
		number: '47(a)',
		question: 'మీ కుటుంబం నుండి ఎవరైనా వలస వెళ్ళారా?',
		questionEng: 'Has anyone from your family migrated?',
		entries: [{
			code: '01',
			value: 'వలస వెళ్ళలేదు',
			valueEng: 'Not migrated'
		},{
			code: '02',
			value: 'ఇతర దేశాలకు వెళ్లారు',
			valueEng: 'Migrated to other countries'
		}, {
			code: '03',
			value: 'ఇతర రాష్ట్రాలకు వెళ్లారు',
			valueEng: 'Migrated to other states'
		}]
	},
	'47(b)': {
		number: '47(b)',
		question: 'ఇతర దేశం అయితే దేశం పేరు వ్రాయండి',
		questionEng: 'If other country, select country name.',
		entries: [{
			code: '01',
			value: 'యూకే (UK)',
			valueEng: 'UK'
		}, {
			code: '02',
			value: 'యూరప్ దేశాలు',
			valueEng: 'European Countries'
		}, {
			code: '03',
			value: 'యూఎస్ (US)',
			valueEng: 'US / America'
		}, {
			code: '04',
			value: 'గల్ఫ్ దేశాలు',
			valueEng: 'Gulf Countries'
		}, {
			code: '05',
			value: 'ఆస్ట్రేలియా',
			valueEng: 'Australia'
		}, {
			code: '06',
			value: 'కెనడా',
			valueEng: 'Canada'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'47(c)': {
		number: '47(c)',
		question: 'ఇతర రాష్ట్రం అయితే రాష్ట్రం పేరు వ్రాయండి',
		questionEng: 'If other state, select the name of the state.',
		entries: [{
			code: '01',
			value: 'ఆంధ్ర ప్రదేశ్',
			valueEng: 'Andhra Pradesh'
		}, {
			code: '02',
			value: 'అరుణాచల్ ప్రదేశ్',
			valueEng: 'Arunachal Pradesh'
		}, {
			code: '03',
			value: 'అస్సాం',
			valueEng: 'Assam'
		}, {
			code: '04',
			value: 'బీహార్',
			valueEng: 'Bihar'
		}, {
			code: '05',
			value: 'ఛత్తీస్గఢ్',
			valueEng: 'Chattisgarh'
		}, {
			code: '06',
			value: 'గోవా',
			valueEng: 'Goa'
		}, {
			code: '07',
			value: 'గుజరాత్',
			valueEng: 'Gujarat'
		}, {
			code: '08',
			value: 'హర్యానా',
			valueEng: 'Haryana'
		}, {
			code: '09',
			value: 'హిమాచల్ ప్రదేశ్',
			valueEng: 'Himachal Pradesh'
		}, {
			code: '10',
			value: 'జార్ఖండ్',
			valueEng: 'Jharkhand'
		}, {
			code: '11',
			value: 'కర్ణాటక',
			valueEng: 'Karnataka'
		}, {
			code: '12',
			value: 'కేరళ',
			valueEng: 'Kerala'
		}, {
			code: '13',
			value: 'మధ్యప్రదేశ్',
			valueEng: 'Madhya Pradesh'
		}, {
			code: '14',
			value: 'మహారాష్ట్ర',
			valueEng: 'Maharashtra'
		}, {
			code: '15',
			value: 'మణిపూర్',
			valueEng: 'Manipur'
		}, {
			code: '16',
			value: 'మేఘాలయ',
			valueEng: 'Meghalaya'
		}, {
			code: '17',
			value: 'మిజోరం',
			valueEng: 'Mizoram'
		}, {
			code: '18',
			value: 'నాగాలాండ్',
			valueEng: 'Nagaland'
		}, {
			code: '19',
			value: 'ఒడిశా',
			valueEng: 'Odisha'
		}, {
			code: '20',
			value: 'పంజాబ్',
			valueEng: 'Punjab'
		}, {
			code: '21',
			value: 'రాజస్థాన్',
			valueEng: 'Rajasthan'
		}, {
			code: '22',
			value: 'సిక్కిం',
			valueEng: 'Sikkim'
		}, {
			code: '23',
			value: 'తమిళనాడు',
			valueEng: 'Tamil Nadu'
		}, {
			code: '24',
			value: 'త్రిపుర',
			valueEng: 'Tripura'
		}, {
			code: '25',
			value: 'ఉత్తర ప్రదేశ్',
			valueEng: 'Uttar Pradesh'
		}, {
			code: '26',
			value: 'ఉత్తరాఖండ్',
			valueEng: 'Uttarakhand'
		}, {
			code: '27',
			value: 'పశ్చిమ బెంగాల్',
			valueEng: 'West Bengal'
		}, {
			code: '28',
			value: 'ఢిల్లీ',
			valueEng: 'Delhi'
		}, {
			code: '29',
			value: 'అండమాన్ & నికోబార్ దీవులు (UT)',
			valueEng: 'Andaman & Nicobar Islands (UT)'
		}, {
			code: '30',
			value: 'చండీగఢ్ (UT)',
			valueEng: 'Chandigarh (UT)'
		}, {
			code: '31',
			value: 'జమ్మూ & కాశ్మీర్ (UT)',
			valueEng: 'Jammu & Kashmir (UT)'
		}, {
			code: '32',
			value: 'లడఖ్ (UT)',
			valueEng: 'Ladakh (UT)'
		}, {
			code: '33',
			value: 'లక్షద్వీప్ (UT)',
			valueEng: 'Lakshadweep (UT)'
		}, {
			code: '34',
			value: 'పుదుచ్చేరి (UT)',
			valueEng: 'Puducherry'
		}, {
			code: '35',
			value: 'దాద్రానగర్ హవేలి మరియు డామన్ & డయ్యు (UT)',
			valueEng: 'Dadra & Nagar Haveli & Daman & Diu (UT) '
		}]
	},
	'47(d)': {
		number: '47(d)',
		question: 'వలస వెళ్ళినట్లైతే వెళ్లడానికి గల కారణం',
		questionEng: 'If migrated, reason for migration.',
		entries: [{
			code: '01',
			value: 'ఉన్నత చదువులు',
			valueEng: 'Higher studies'
		}, {
			code: '02',
			value: 'ఉద్యోగం',
			valueEng: 'Job'
		}, {
			code: '03',
			value: 'వ్యాపారం',
			valueEng: 'Business'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'48(b)': {
		number: '48(b)',
		question: '48(a) కాలమ్ లో అవును అయితే; ఋణం దేని కొరకు తీసుకున్నారో తెలపండి',
		questionEng: `If 'Yes' in Column 49 (a), state the purpose for which the loan was taken for.`,
		entries: [{
			code: '01',
			value: 'ఇంటి నిర్మాణం',
			valueEng: 'Construction of the house'
		}, {
			code: '02',
			value: 'భూమి కొనుగోలు',
			valueEng: 'Purchase of land'
		}, {
			code: '03',
			value: 'వ్యాపారం',
			valueEng: 'Business'
		}, {
			code: '04',
			value: 'వివాహం',
			valueEng: 'Wedding'
		}, {
			code: '05',
			value: 'ఇతర ధార్మిక మరియు సామాజిక కార్యక్రమాలు',
			valueEng: 'Other charitable and social programs'
		}, {
			code: '06',
			value: 'వాహన కొనుగోలు',
			valueEng: 'Vehicle (car, bike, motorcycle) purchase'
		}, {
			code: '07',
			value: 'పంట',
			valueEng: 'Crop'
		}, {
			code: '08',
			value: 'విద్య',
			valueEng: 'Education'
		}, {
			code: '09',
			value: 'వైద్యం',
			valueEng: 'Medical'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'48(c)': {
		number: '48(c)',
		question: 'ఎక్కడి నుండి ఋణం పొందారు వివరాలను తెలియజేయండి',
		questionEng: 'Give the details from where the loan was obtained.',
		entries: [{
			code: '01',
			value: 'షెడ్యూల్డ్ బ్యాంకులు',
			valueEng: 'Scheduled Banks'
		}, {
			code: '02',
			value: 'కోఆపరేటివ్ బ్యాంకులు',
			valueEng: 'Cooperative Banks'
		}, {
			code: '03',
			value: 'స్త్రీ నిధి',
			valueEng: 'Streenidhi'
		}, {
			code: '04',
			value: 'జిల్లా సమాఖ్య, మండల సమాఖ్య, స్వయం సహాయక సంఘాలు',
			valueEng: 'Zilla Samakhya, Mandal Samakhya, Self Help Groups'
		}, {
			code: '05',
			value: 'ప్రైవేట్ బ్యాంక్',
			valueEng: 'Private Bank'
		}, {
			code: '06',
			value: 'వడ్డీ వ్యాపారులు',
			valueEng: 'Moneylenders'
		}, {
			code: '07',
			value: 'బ్యాంకేతర ఆర్థిక సంస్థలు',
			valueEng: 'Non-Banking Financial Institutions'
		}, {
			code: '00',
			value: 'ఇతరులు',
			valueEng: 'Others'
		}]
	},
	'49': {
		number: '49',
		question: 'వ్యవసాయ అనుబంధ కార్యకలాపాల వివరాలను తెలియజేయండి.',
		questionEng: 'Give details of agriculture related activities.',
		entries: [{
			code: '01',
			value: 'ఎటువంటి వ్యవసాయ అనుబంధ కార్యకలాపాలు లేవు.',
			valueEng: 'No agriculture related activities.'
		}, {
			code: '02',
			value: 'డెయిరీ',
			valueEng: 'Dairy'
		}, {
			code: '03',
			value: 'కోళ్ళ పెంపకం',
			valueEng: 'Poultry farming'
		}, {
			code: '04',
			value: 'చేపల పెంపకం',
			valueEng: 'Fish farming'
		}, {
			code: '05',
			value: 'మేకల / గొర్రెల పెంపకం',
			valueEng: 'Goat / Sheep rearing'
		}, {
			code: '06',
			value: 'పందుల పెంపకం',
			valueEng: 'Pig breeding'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'51': {
		number: '51',
		question: 'అందరు సభ్యుల యాజమాన్యంలోని మొత్తం స్థిరాస్తుల వివరాలను వ్రాయండి.',
		questionEng: 'Write the details of all the immovable properties owned by all the members.',
		entries: [{
			code: '01',
			value: 'గృహాలు',
			valueEng: 'Houses'
		}, {
			code: '02',
			value: 'వాణిజ్య భవనం',
			valueEng: 'Commercial buildings'
		}, {
			code: '03',
			value: 'ఫ్యాక్టరీ భవనం',
			valueEng: 'Factory buildings'
		}, {
			code: '04',
			value: 'భూమి (వ్యవసాయేతర భూమి / ఇంటి స్థలం)',
			valueEng: 'Land (Non-Agricultural Land / House Plot)'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'52': {
		number: '52',
		question: 'అందరు సభ్యుల మొత్తం చరాస్తుల వివరాలను పేర్కొనండి.',
		questionEng: 'State the details of the total assets of all the members.',
		entries: [{
			code: '01',
			value: 'మోటార్ సైకిల్ / స్కూటర్',
			valueEng: 'Motorcycle / Scooter'
		}, {
			code: '02',
			value: 'మూడు చక్రాల వాహనం',
			valueEng: 'Three wheeler'
		}, {
			code: '03',
			value: 'సొంత అవసరాల కోసం వాడే కారు (Car)',
			valueEng: 'Car used for own purposes'
		}, {
			code: '04',
			value: 'ఆదాయం కోసం వాడే కారు (Car)',
			valueEng: 'Car used for income (taxi)'
		}, {
			code: '05',
			value: 'వ్యాన్ / బస్',
			valueEng: 'Van / Bus'
		}, {
			code: '06',
			value: 'రిఫ్రిజిరేటర్',
			valueEng: 'Refrigerator'
		}, {
			code: '07',
			value: 'వాషింగ్ మెషీన్',
			valueEng: 'Washing machine'
		}, {
			code: '08',
			value: 'కంప్యూటర్',
			valueEng: 'Computer'
		}, {
			code: '09',
			value: 'ఇంటర్నెట్ వసతి',
			valueEng: 'Internet connection'
		}, {
			code: '10',
			value: 'ఎయిర్ కండిషన్',
			valueEng: 'Air conditioner (AC)'
		}, {
			code: '11',
			value: 'టెలివిజన్',
			valueEng: 'Television'
		}, {
			code: '12',
			value: 'స్మార్ట్ ఫోన్',
			valueEng: 'Smart phone'
		}, {
			code: '13',
			value: 'పెస్టిసైడ్ స్ప్రేయింగ్ మెషీన్',
			valueEng: 'Pesticide spraying machine'
		}, {
			code: '14',
			value: 'పవర్ టిల్లర్',
			valueEng: 'Power tiller'
		}, {
			code: '15',
			value: 'ట్రాక్టర్',
			valueEng: 'Tractor'
		}, {
			code: '16',
			value: 'విత్తనం వేరుచేసే యంత్రం',
			valueEng: 'Seed separator'
		}, {
			code: '17',
			value: 'పంటకోత యంత్రం',
			valueEng: 'Harvester'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'54(a)': {
		number: '54(a)',
		question: 'నివాస గృహం ఉన్న ప్రాంత స్వభావాన్ని తెలియజేయండి.',
		questionEng: 'State the nature of the residential area.',
		entries: [{
			code: '01',
			value: 'గ్రామీణ',
			valueEng: 'Rural'
		}, {
			code: '02',
			value: 'పట్టణ',
			valueEng: 'Urban'
		}, {
			code: '03',
			value: 'మురికి వాడలు',
			valueEng: 'Slums'
		}, {
			code: '04',
			value: 'సెమీ అర్బన్',
			valueEng: 'Semi-urban'
		}, {
			code: '05',
			value: 'అటవీ / కొండ ప్రాంతాలు',
			valueEng: 'Forest / Hilly area'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'54(c)': {
		number: '54(c)',
		question: 'ప్రస్తుత నివాస గృహ యాజమాన్య స్థితిని తెలియజేయండి',
		questionEng: 'Indicate the current housing ownership status.',
		entries: [{
			code: '01',
			value: 'స్వంతం',
			valueEng: 'Own house'
		}, {
			code: '02',
			value: 'కిరాయి',
			valueEng: 'Rent'
		}, {
			code: '03',
			value: 'ప్రభుత్వ క్వార్టర్లు',
			valueEng: 'Government quarters'
		}, {
			code: '04',
			value: 'ప్రభుత్వ సహాయంతో ఇల్లు',
			valueEng: 'Government aided housing'
		}, {
			code: '05',
			value: 'ఇల్లు లేని వారు',
			valueEng: 'Homeless'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'54(d)': {
		number: '54(d)',
		question: 'నివాస గృహం రకం వివరాలను తెలియజేయండి',
		questionEng: '',
		entries: [{
			code: '01',
			value: 'పూరి గుడిసె',
			valueEng: 'Puri Gudis'
		}, {
			code: '02',
			value: 'పెంకుటిల్లు / రేకుల ఇల్లు',
			valueEng: 'Penkutillu / Rekula Illu'
		}, {
			code: '03',
			value: 'పక్కా ఇల్లు / RCC ఇల్లు',
			valueEng: 'Pucca House / RCC House'
		}, {
			code: '04',
			value: 'ప్లాట్ / అపార్ట్మెంట్',
			valueEng: 'Plot / Apartment'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'54(f)': {
		number: '54(f)',
		question: 'మీ ఇంటి గోడలు ప్రధానంగా దేనితో నిర్మించారు.',
		questionEng: 'What are the walls of your house mainly made of?',
		entries: [{
			code: '01',
			value: 'తడికలు',
			valueEng: ''
		}, {
			code: '02',
			value: 'ప్లాస్టిక్ / పాలిథిన్',
			valueEng: 'Plastic / Polythene'
		}, {
			code: '03',
			value: 'బురద మట్టి / కాల్చని ఇటుకలు',
			valueEng: 'Mud clay / unfired bricks'
		}, {
			code: '04',
			value: 'చెక్క',
			valueEng: 'Wood'
		}, {
			code: '05',
			value: 'రాళ్లు',
			valueEng: 'Stones'
		}, {
			code: '06',
			value: 'ఆస్బెస్టాస్ / ఇనుప రేకులు',
			valueEng: 'Asbestos / iron flakes'
		}, {
			code: '07',
			value: 'కాల్చిన ఇటుకలు',
			valueEng: 'Fired bricks'
		}, {
			code: '08',
			value: 'కాంక్రీట్',
			valueEng: 'Concrete'
		}, {
			code: '09',
			value: 'సిమెంటు ఇటుకలు',
			valueEng: 'Cement bricks'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Other'
		}]
	},
	'54(g)': {
		number: '54(g)',
		question: 'త్రాగునీటి సౌకర్యాల వివరాలను తెలుపండి.',
		questionEng: 'Give details of drinking water facilities.',
		entries: [{
			code: '01',
			value: 'ఇంటికి నల్ల (మంచి నీటి) కనెక్సన్',
			valueEng: 'Drinking water connection'
		}, {
			code: '02',
			value: 'చేతి పంపు',
			valueEng: 'Hand pump'
		}, {
			code: '03',
			value: 'నది లేదా కాలువ',
			valueEng: 'River or canal'
		}, {
			code: '04',
			value: 'పబ్లిక్ నల్లా',
			valueEng: 'Public Taps'
		}, {
			code: '05',
			value: 'పబ్లిక్ బావి',
			valueEng: 'Public well'
		}, {
			code: '06',
			value: 'బావి / బోరు బావి (ఇంటి ఆవరణలో)',
			valueEng: 'Well / Borewell (in house premises)'
		}, {
			code: '00',
			value: 'ఇతరములు',
			valueEng: 'Others'
		}]
	},
	'54(j)': {
		number: '54(j)',
		question: 'వంట కోసం ఉపయోగించే ప్రధాన ఇంధనం వివరాలు తెల్పండి',
		questionEng: 'Give details of drinking water facilities.',
		entries: [{
			code: '01',
			value: 'ఎల్పిజి',
			valueEng: 'LPG'
		}, {
			code: '02',
			value: 'వంట చెరకు',
			valueEng: 'Cooking cane'
		}, {
			code: '03',
			value: 'బయో గ్యాస్',
			valueEng: 'Bio gas'
		}, {
			code: '00',
			value: 'ఇతరములు (కిరోసిన్ / విద్యుత్ / సౌర)',
			valueEng: 'Others (Kerosene / Electricity / Solar)'
		}]
	}
};

export default data;
export { questionsListData };