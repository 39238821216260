import * as React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import Header from './Header';
import Footer from './Footer';
import { AppContext } from './AppContext';
import { questionsListData } from '../data';

export default function AppContainer() {
	const navigate = useNavigate();
	const location = useLocation();

  const { globalState, setGlobalState } = React.useContext(AppContext);

  React.useEffect(() => {
  	/**
  	 * Checks if the location contains a valid question number.
  	 * 
  	 * @return {Integer} -1, if question number is invalid. Otherwise, the question number.
  	 */
  	function checkIfQuestionValid() {
  	  // const q = location.pathname.split('/').at(-1);
  	  const q = location.pathname.split('/').pop();

  	  if (questionsListData.indexOf(q) === -1) {
  	    return -1;
  	  }

  	  return q;
  	}
  	
    const result = checkIfQuestionValid();
    if (result === -1) {
      navigate('/');
    } else {
    	setGlobalState({
    		...globalState,
    		questionNum: result
    	});
    }
  }, [ location ]);

	return (
		<div style={{ height: '100%' }}>
			<AppShell padding="md"
				header={{ height: 60 }} 
				footer={{ height: 60 }}
			>
				<AppShell.Header>
					<Header />
				</AppShell.Header>

				<AppShell.Main >
					<div style={{ display: "block" }}>
						{
							globalState && globalState.questionNum ? (
								<Outlet />
							): (
								<></>
							)
						}
					</div>
				</AppShell.Main>

				<AppShell.Footer>
					<Footer />
				</AppShell.Footer>
			</AppShell>
		</div>
	);
}